import React, { useEffect, useState } from "react";
import "./targetMarket.scss";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

// api import
import { getTargetMarketData } from "../../apis/targetMarket.apis";

// components
import Wrapper from "../../components/common/wrapper/Wrapper";
import SubHero from "../../components-ui/heroSections/subHero/SubHero";
import AppLayout from "../../components/layouts/appLayout/AppLayout";

const TargetMarket = () => {
  const [targetMarketData, setTargetMarketData] = useState([]);
  useEffect(() => {
    getTargetMarketData()
      .then((res) => {
        setTargetMarketData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <AppLayout>
      <div className="targetMarket">
        <SubHero
          title={targetMarketData && targetMarketData?.TITLE}
          desc={targetMarketData && targetMarketData?.DESCRIPTION}
        />

        <Wrapper>
          <div className="common__headerLink__component">
            <Link to="/" className="common__headerLinkContainer">
              <span className="linkText">Home</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <Link
              to=""
              className="common__headerLinkContainer common__headerLinkContainer__active"
            >
              <span className="linkText">Target Market</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>
          </div>

          <div className="targetMarket__container">
            {targetMarketData?.tmarketList?.map((item, index) => {
              return (
                <div className="targetMarket__block" key={index}>
                  <div className="targetMarket__block__Left">
                    <div className="targetMarket__block__LeftImage">
                      <img src={item?.IMAGE_LINK_1} alt="" />
                    </div>
                  </div>

                  <div className="targetMarket__block__Right">
                    <div className="targetMarket__block__RightTitle">
                      <span>{item?.NAME}</span>
                    </div>

                    <div className="targetMarket__block__rightDesc">
                      <span>
                        {item?.DESCRIPTION && parse(item.DESCRIPTION)}
                      </span>
                    </div>

                    <div className="targetMarket__block__listItems">
                      {item?.categorylist &&
                        item?.categorylist.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="targetMarket__block__listItem"
                            >
                              <div className="targetMarket__block__listBullet"></div>
                              <span>{item}</span>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </Wrapper>
      </div>
    </AppLayout>
  );
};

export default TargetMarket;
