import React from "react";
import "./subHero.scss";
import parse from "html-react-parser";

import img1 from "./Half-Banner-2.jpg";
import Wrapper from "../../../components/common/wrapper/Wrapper";

const SubHero = ({ data, title, desc }) => {
  return (
    <div className="subHero">
      {data ? (
        <>
          <img src={data ? data?.SLIDER_IMAGE : img1} alt="" />
        </>
      ) : (
        <div className="subHero__content">
          <Wrapper>
            <div className="subHero__contentDiv">
              <h1 className="subHero__contentTitle">{title}</h1>
              <span className="subHero__contentdesc">
                {desc && parse(desc)}
              </span>
            </div>
          </Wrapper>
        </div>
      )}
    </div>
  );
};

export default SubHero;
