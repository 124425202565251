import React, { useEffect, useState } from "react";
import "./downloadPage.scss";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

// icons
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import pdfIcon from "./assets/pdf_icon.svg";

// react collapsible
import Collapsible from "react-collapsible";

// apis
import { getSubHeadersData } from "../../apis/subHeader.apis";
import {
  getDownloadFilterItems,
  getDownloadFilteredData,
} from "../../apis/download.apis";

// components
import Wrapper from "../../components/common/wrapper/Wrapper";
import SubHero from "../../components-ui/heroSections/subHero/SubHero";
import AppLayout from "../../components/layouts/appLayout/AppLayout";
import BorderBtn from "../../components/buttons/borderBtn/BorderBtn";

const DownloadPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  // states
  const [subHeaderData, setSubHeaderData] = useState([]);
  const [downloadData, setDownloadData] = useState(null);
  const [visivleItemsCount, setVisibleItemsCount] = useState(8);
  const [filterItems, setFilterItems] = useState(null);
  const [subCategory, setSubCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);

  // title state
  const [typeTitle, setTypeTitle] = useState("All");
  const [brandTitle, setBrandTitle] = useState(null);
  const [categoryTitle, setCategoryTitle] = useState(null);
  const [subCategoryTitle, setSubCategoryTitle] = useState(null);

  const [isOpenFilter, setIsOpenFilter] = useState(true);
  const handleTriggerFilter = () => {
    setIsOpenFilter(!isOpenFilter);
  };

  const [isOpenFileType, setIsOpenFileType] = useState(true);
  const [isOpenFileB, setIsOpenFileB] = useState(false);
  const [isOpenFileC, setIsOpenFileC] = useState(false);
  const [isOpenFileSub, setIsOpenFileSub] = useState(true);
  const handleTriggerFilterT = () => {
    setIsOpenFileType(!isOpenFileType);
  };
  const handleTriggerFilterB = () => {
    setIsOpenFileB(!isOpenFileB);
  };
  const handleTriggerFilterC = () => {
    setIsOpenFileC(!isOpenFileC);
  };
  const handleTriggerFilterSub = () => {
    setIsOpenFileSub(!isOpenFileSub);
  };

  const loadMoreDoc = () => {
    setVisibleItemsCount((prevCount) => prevCount + 8);
  };

  // filter functionality
  const [filters, setFilters] = useState({
    filetype: searchParams.get("filetype") || "",
    brand: searchParams.get("brand") || "",
    category: searchParams.get("category") || "",
    subcategory: searchParams.get("subcategory") || "",
  });
  useEffect(() => {
    const newParams = {};

    const addCommaSeparatedParams = (key, values) => {
      if (Array.isArray(values) && values.length > 0) {
        newParams[key] = values.join(",");
      } else if (values) {
        newParams[key] = values;
      }
    };

    addCommaSeparatedParams("filetype", filters.filetype);
    addCommaSeparatedParams("brand", filters.brand);
    addCommaSeparatedParams("category", filters.category);
    addCommaSeparatedParams("subcategory", filters.subcategory);

    setSearchParams(newParams);
  }, [filters, setSearchParams]);

  useEffect(() => {
    // sub header
    getSubHeadersData()
      .then((res) => {
        const data = res.data.filter((item) => item.PAGE_SHORT_NAME === "DN");
        setSubHeaderData(data);
      })
      .catch((err) => {
        console.log(err);
      });

    getDownloadFilterItems()
      .then((res) => {
        setFilterItems(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (selectedCategory !== null && filterItems) {
      const selectedCategoryData = filterItems[3].FilterList.filter(
        (item) => item.CATEGORY_ID === selectedCategory
      );
      if (selectedCategoryData.length > 0) {
        setSubCategory(selectedCategoryData);
      } else {
        setSubCategory([]);
      }
    } else {
      setSubCategory([]);
    }
  }, [selectedCategory, filterItems]);

  useEffect(() => {
    setLoading(true);

    getDownloadFilteredData(filters)
      .then((res) => {
        // console.log("res", res.data);
        setLoading(false);
        setDownloadData(res.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [filters, setSearchParams]);

  const handleFilterChange = (filterType, value, sub, title) => {
    setVisibleItemsCount(8);

    if (filterType === "category") {
      if (filters[filterType] === value) {
        setFilters((prevState) => ({
          ...prevState,
          [filterType]: "",
          subcategory: "",
        }));
        setSelectedCategory(null);
      } else if (value) {
        setFilters((prevState) => ({
          ...prevState,
          [filterType]: value,
          subcategory: "",
        }));
        setSelectedCategory(sub);
        setIsOpenFileSub(true);
      } else {
        setFilters((prevState) => ({
          ...prevState,
          [filterType]: value,
          subcategory: "",
        }));
        setSelectedCategory(null);
      }
    } else if (filters[filterType] === value) {
      setFilters((prevState) => ({
        ...prevState,
        [filterType]: "",
      }));
    } else {
      setFilters((prevState) => ({
        ...prevState,
        [filterType]: value,
      }));
    }

    if (filterType === "filetype") {
      if (filters[filterType] === value) {
        setTypeTitle("All Documents");
      } else {
        setTypeTitle(title);
      }
    }
    if (filterType === "brand") {
      if (brandTitle === title) {
        setBrandTitle(null);
      } else {
        setBrandTitle(title);
      }
    }
    if (filterType === "category") {
      if (categoryTitle === title) {
        setCategoryTitle(null);
        setSubCategoryTitle(null);
      } else {
        setCategoryTitle(title);
      }
    }
    if (filterType === "subcategory") {
      if (subCategoryTitle === title) {
        setSubCategoryTitle(null);
      } else {
        setSubCategoryTitle(title);
      }
    }
  };

  return (
    <AppLayout>
      <div className="downloadPage">
        <SubHero
          title={subHeaderData && subHeaderData[0]?.TITLE}
          desc={subHeaderData && subHeaderData[0]?.DESCRIPTION}
        />

        <Wrapper>
          <div className="common__headerLink__component">
            <Link to="/" className="common__headerLinkContainer">
              <span className="linkText">Home</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <Link
              to=""
              className="common__headerLinkContainer common__headerLinkContainer__active"
            >
              <span className="linkText">Download</span>
            </Link>
          </div>

          <div className="downloadPage__container">
            <div className="downloadPage__productFilterContainer">
              <div className="downloadPage__pfLeft">
                <div className="downloadPage__filterContainer">
                  <div className="dpf__header">
                    <span>Filter</span>
                  </div>
                  {/* {filterItems && (
                  <DownloadSidebarCollapsible
                    data={filterItems[0]}
                    isOpen={true}
                    type={type}
                    setType={setType}
                    filters={filters}
                    setFilters
                    filterType={"filetype"}
                    value
                  />
                )} */}
                  {filterItems && (
                    <div className="dpf__filterSection">
                      <Collapsible
                        open={isOpenFileType}
                        trigger={
                          <div
                            className="dpf__filterSectionHeader"
                            onClick={handleTriggerFilterT}
                          >
                            <span>{filterItems[0].FILTER_TITLE}</span>
                            {isOpenFileType ? (
                              <MdKeyboardArrowUp />
                            ) : (
                              <MdKeyboardArrowDown />
                            )}
                          </div>
                        }
                      >
                        <div className="dpf__filterSectionBody">
                          {filterItems[0].FilterList &&
                            filterItems[0].FilterList?.map((option, index) => {
                              return (
                                <div
                                  className="dpf__filterOption"
                                  key={index}
                                  onClick={() =>
                                    handleFilterChange(
                                      "filetype",
                                      option.filter_value,
                                      null,
                                      option.Name
                                    )
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    value={option.filter_value}
                                    checked={
                                      filters.filetype === option.filter_value
                                    }
                                    readOnly
                                  />
                                  <label htmlFor="">{option.Name}</label>
                                </div>
                              );
                            })}
                        </div>
                      </Collapsible>
                    </div>
                  )}
                  {filterItems && (
                    <div className="dpf__filterSection">
                      <Collapsible
                        open={isOpenFileB}
                        trigger={
                          <div
                            className="dpf__filterSectionHeader"
                            onClick={handleTriggerFilterB}
                          >
                            <span>{filterItems[1].FILTER_TITLE}</span>
                            {isOpenFileB ? (
                              <MdKeyboardArrowUp />
                            ) : (
                              <MdKeyboardArrowDown />
                            )}
                          </div>
                        }
                      >
                        <div className="dpf__filterSectionBody">
                          {filterItems[1].FilterList &&
                            filterItems[1].FilterList?.map((option, index) => {
                              return (
                                <div
                                  className="dpf__filterOption"
                                  key={index}
                                  onClick={() =>
                                    handleFilterChange(
                                      "brand",
                                      option.filter_value,
                                      null,
                                      option.Name
                                    )
                                  }
                                  disabled={loading}
                                >
                                  <input
                                    type="checkbox"
                                    value={option.filter_value}
                                    checked={
                                      filters.brand === option.filter_value
                                    }
                                    readOnly
                                  />
                                  <label htmlFor="">{option.Name}</label>
                                </div>
                              );
                            })}
                        </div>
                      </Collapsible>
                    </div>
                  )}
                  {filterItems && (
                    <div className="dpf__filterSection">
                      <Collapsible
                        open={isOpenFileC}
                        trigger={
                          <div
                            className="dpf__filterSectionHeader"
                            onClick={handleTriggerFilterC}
                          >
                            <span>{filterItems[2].FILTER_TITLE}</span>
                            {isOpenFileC ? (
                              <MdKeyboardArrowUp />
                            ) : (
                              <MdKeyboardArrowDown />
                            )}
                          </div>
                        }
                      >
                        <div className="dpf__filterSectionBody">
                          {filterItems[2].FilterList &&
                            filterItems[2].FilterList?.map((option, index) => {
                              return (
                                <div
                                  className="dpf__filterOption"
                                  key={index}
                                  onClick={() =>
                                    handleFilterChange(
                                      "category",
                                      option.filter_value,
                                      option.ID,
                                      option.Name
                                    )
                                  }
                                  disabled={loading}
                                >
                                  <input
                                    type="checkbox"
                                    value={option.filter_value}
                                    checked={
                                      filters.category === option.filter_value
                                    }
                                    readOnly
                                  />
                                  <label htmlFor="">{option.Name}</label>
                                </div>
                              );
                            })}
                        </div>
                      </Collapsible>
                    </div>
                  )}
                  {/* {subCategory && subCategory.length > 0 && ( */}
                  <div className="dpf__filterSection">
                    <Collapsible
                      open={isOpenFileSub}
                      trigger={
                        <div
                          className="dpf__filterSectionHeader"
                          onClick={handleTriggerFilterSub}
                        >
                          {/* <span>{filterItems[3].FILTER_TITLE}</span> */}
                          <span>Sub-Category</span>
                          {isOpenFileSub ? (
                            <MdKeyboardArrowUp />
                          ) : (
                            <MdKeyboardArrowDown />
                          )}
                        </div>
                      }
                    >
                      <div className="dpf__filterSectionBody">
                        {subCategory.length === 0 && (
                          <div className="dpf__filterOption">
                            <span>Select a category first.</span>
                          </div>
                        )}

                        {subCategory &&
                          subCategory.length > 0 &&
                          subCategory?.map((option, index) => {
                            return (
                              <div
                                className="dpf__filterOption"
                                key={index}
                                onClick={() =>
                                  handleFilterChange(
                                    "subcategory",
                                    option.filter_value,
                                    null,
                                    option.Name
                                  )
                                }
                                disabled={loading}
                              >
                                <input
                                  type="checkbox"
                                  value={option.filter_value}
                                  checked={
                                    filters.subcategory === option.filter_value
                                  }
                                  readOnly
                                />
                                <label htmlFor="">{option.Name}</label>
                              </div>
                            );
                          })}
                      </div>
                    </Collapsible>
                  </div>
                  {/* // )} */}
                </div>
              </div>

              <div className="downloadPage__pfRight">
                <div className="download__header">
                  <h4>
                    {typeTitle ? typeTitle : "All}"}
                    {brandTitle ? " > " + brandTitle : ""}
                    {categoryTitle ? " > " + categoryTitle : ""}
                    {subCategoryTitle ? " > " + subCategoryTitle : ""}
                  </h4>
                </div>

                <div className="downloadPage__documentList">
                  {loading ? (
                    <div className="loading">
                      <span>Loading...</span>
                    </div>
                  ) : (
                    <>
                      {downloadData && downloadData.length === 0 && (
                        <div className="noData">
                          <span>No Data Found</span>
                        </div>
                      )}
                      {downloadData &&
                        downloadData.length > 0 &&
                        downloadData
                          .slice(0, visivleItemsCount)
                          .map((item, index) => {
                            return (
                              <a
                                className="downloadPage__pfRightItem"
                                href={item.FILE_PATH}
                                target="_blank"
                                rel="noreferrer"
                                key={index}
                              >
                                <img
                                  src={item.ITEM_IMAGE}
                                  alt=""
                                  className="downloadPage__pfRightItemImg"
                                />

                                <div className="name">
                                  <span>{item.ITEM_NAME}</span>
                                  <img src={pdfIcon} alt="" className="s" />
                                </div>
                              </a>
                            );
                          })}
                    </>
                  )}
                </div>

                <div className="downloadPage__loadMoreDiv">
                  {loading ? (
                    <></>
                  ) : (
                    downloadData &&
                    downloadData.length > visivleItemsCount && (
                      <div onClick={loadMoreDoc}>
                        <BorderBtn
                          name={"Load More"}
                          size={"medium"}
                          color={"white"}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
    </AppLayout>
  );
};

export default DownloadPage;
