import React from "react";
import "./confirmationPage.scss";
import { Link } from "react-router-dom";

// components import
import AppLayout from "../../components/layouts/appLayout/AppLayout";
import Wrapper from "../../components/common/wrapper/Wrapper";

const ConfirmationPage = () => {
  return (
    <AppLayout>
      <Wrapper>
        <div className="confirmationPage">
          <h2>Successfully Submited</h2>

          <Link to="/" className="conf link">
            Go to Home
          </Link>
        </div>
      </Wrapper>
    </AppLayout>
  );
};

export default ConfirmationPage;
