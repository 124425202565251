import React from "react";
import "./productCard.scss";
import { Link } from "react-router-dom";
import parser from "html-react-parser";

// components
// import TextIconBtnSmall from "../../common/buttons/textIconBtnSmall/TextIconBtnSmall";
import PrimaryBtn from "../../buttons/primaryBtn/PrimaryBtn";

const ProductCard = ({ data, link, btnName }) => {
  return (
    <Link to={link} className="productCard link">
      <div className="productCard__Img">
        <img src={data?.ITEM_IMAGE} alt="" />
      </div>

      <div className="productCard__Content">
        <div className="gridCard__nameDesc">
          <span className="productCard__catName">{data?.ITEM_NAME}</span>
          <div className="productCard__catDesc">
            {data?.SHORT_DESCRIPTION && parser(data?.SHORT_DESCRIPTION)}
          </div>
        </div>

        <span className="productCard__BtnHolder">
          {/* <TextIconBtnSmall name={btnName} /> */}
          <PrimaryBtn
            link={link}
            name={btnName}
            size={"small"}
            color={"gray"}
          />
        </span>
      </div>
    </Link>
  );
};

export default ProductCard;
