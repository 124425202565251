import React, { useEffect, useState } from "react";
import "./referenceProjectList.scss";
import { Link, useParams } from "react-router-dom";

// apis import
import { getSubHeadersData } from "../../../apis/subHeader.apis";
import { getReferenceProjectLandingPageData } from "../../../apis/reference.apis";

// components import
import Wrapper from "../../../components/common/wrapper/Wrapper";
import SubHero from "../../../components-ui/heroSections/subHero/SubHero";
import AppLayout from "../../../components/layouts/appLayout/AppLayout";
import PrimaryBtn from "../../../components/buttons/primaryBtn/PrimaryBtn";

const ReferenceProjectList = () => {
  const { refTypeId: id } = useParams();

  // states
  const [subHeaderData, setSubHeaderData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refProjectListData, setRefProjectListData] = useState([]);

  useEffect(() => {
    setLoading(true);

    // sub header
    getSubHeadersData()
      .then((res) => {
        const data = res.data.filter((item) => item.PAGE_SHORT_NAME === "RP");
        setSubHeaderData(data);
      })
      .catch((err) => {
        console.log(err);
      });

    getReferenceProjectLandingPageData()
      .then((res) => {
        setRefProjectListData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  return (
    <AppLayout>
      <div className="referenceProjectList">
        <SubHero
          title={subHeaderData && subHeaderData[0]?.TITLE}
          desc={subHeaderData && subHeaderData[0]?.DESCRIPTION}
        />

        <Wrapper>
          <div className="common__headerLink__component">
            <Link to="/" className="common__headerLinkContainer">
              <span className="linkText">Home</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <div className="common__headerLinkContainer common__headerLinkContainer__active">
              <span className="linkText">Reference Projects</span>
            </div>
          </div>

          <div className="referenceProjectList__container">
            {loading && <div>Loading...</div>}

            {refProjectListData.length > 0 &&
              refProjectListData.map((item, index) => {
                return (
                  <div
                    className={`referenceProjectList__card ${
                      index % 4 < 2 ? "layout-1" : "layout-2"
                    }`}
                    key={index}
                  >
                    <div className="ref__imgOverlay"></div>

                    <div className="referenceProjectList__cardImage">
                      <img src={item?.PROJECT_IMAGE} alt="referenceProject" />
                    </div>

                    <div className="referenceProjectList__cardContent">
                      <h2>{item?.PROJECT_NAME}</h2>

                      <div className="referenceProjectList__cardContent__Location">
                        <i className="fa-solid fa-map-marker-alt"></i>
                        <p>{item?.PROJECT_ADDRESS}</p>
                      </div>
                    </div>

                    <div className="referenceProjectList__cardButton">
                      <PrimaryBtn
                        link={`/reference/${item?.REF_PROJECT_TYPE_ID}/${item.REF_PROJECT_ID}`}
                        color={"white"}
                        size={"medium"}
                      />
                    </div>
                  </div>
                );
              })}
          </div>
        </Wrapper>
      </div>
    </AppLayout>
  );
};

export default ReferenceProjectList;
