import React, { useState } from "react";
import "./collapsibleComponent.scss";
import { Link } from "react-router-dom";

import Collapsible from "react-collapsible";
import ProductCard from "../cards/productCard/ProductCard";

export default function CollapsibleComponent({
  data1,
  productListData,
  id,
  isCollapsOpen,
  header,
  isSegment,
}) {
  const [isOpen, setIsOpen] = useState(isCollapsOpen || false);
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsible">
      <Collapsible
        open={isOpen}
        trigger={
          <div className="product__collapsibleTrigger" onClick={handleToggle}>
            <h1>
              {data1?.SUBCATEGORY_NAME ||
                data1?.CATEGORY_NAME ||
                data1?.BRAND_NAME ||
                header}

              {productListData?.SUBCATEGORY_NAME ||
                productListData?.CATEGORY_NAME ||
                productListData?.BRAND_NAME ||
                header}
            </h1>

            <div className="pct__right">
              <span>
                Products:{" "}
                {productListData?.productList &&
                  productListData?.productList.length}{" "}
                {data1?.productList && data1?.productList.length}
              </span>

              {isOpen ? (
                <i className="fas fa-chevron-up"> </i>
              ) : (
                <i className="fas fa-chevron-down"> </i>
              )}
            </div>
          </div>
        }
      >
        <div className="gridContainer">
          {productListData?.productList &&
            productListData?.productList?.map((col, i) => {
              return (
                <ProductCard
                  data={col}
                  link={`/products/${col.CATEGORY_ID}/${col?.ITEM_ID}`}
                  btnName={"View Details"}
                  key={i}
                />
              );
            })}

          {data1?.productList &&
            data1?.productList?.map((col, i) => {
              return (
                <ProductCard
                  data={col}
                  link={`/products/${id}/${col?.ITEM_ID}`}
                  btnName={"View Details"}
                  key={i}
                />
              );
            })}
        </div>
      </Collapsible>
    </div>
  );
}
