export const menuData = [
  {
    id: 1,
    title: "About us",
    slug: "about",
    link: "/about",
  },
  {
    id: 2,
    title: "Products",
    slug: "products",
    link: "/products",
  },
  {
    id: 3,
    title: "Reference Projects",
    slug: "projects",
    link: "/reference",
  },
  {
    id: 3,
    title: "Partners",
    slug: "partners",
    link: "/partners",
  },
  {
    id: 4,
    title: "Career",
    slug: "career",
    link: "/career",
  },
  {
    id: 5,
    title: "Downloads",
    slug: "download",
    link: "/download",
  },
  {
    id: 6,
    title: "Contact ",
    slug: "contact",
    link: "/contact",
  },
];
