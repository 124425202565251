import axios from "axios";

export const getLandingCarouselData = () => {
  return axios({
    method: "GET",
    url: "https://www.tocoma.co/api/Slider?page=Home",
  });
};

export const getAboutCarouselData = () => {
  return axios({
    method: "GET",
    url: "https://www.tocoma.co/api/Slider?page=About",
  });
};

export const getProductCarouselData = () => {
  return axios({
    method: "GET",
    url: "https://www.tocoma.co/api/Slider?page=Products",
  });
};

export const getRefProjectCarouselData = () => {
  return axios({
    method: "GET",
    url: "https://www.tocoma.co/api/Slider?page=RefP",
  });
};

export const getPartnerCarouselData = () => {
  return axios({
    method: "GET",
    url: "https://www.tocoma.co/api/Slider?page=Partners",
  });
};

export const getCareerCarouselData = () => {
  return axios({
    method: "GET",
    url: "https://www.tocoma.co/api/Slider?page=Career",
  });
};

export const getDownloadCarouselData = () => {
  return axios({
    method: "GET",
    url: "https://www.tocoma.co/api/Slider?page=Download",
  });
};

export const getContactCarouselData = () => {
  return axios({
    method: "GET",
    url: "https://www.tocoma.co/api/Slider?page=Contact",
  });
};
