import axios from "axios";

import { DOWNLOAD_PAGE_URL } from "../utils/urls";

export const getDownloadPage = () => {
  return axios({
    method: "GET",
    url: DOWNLOAD_PAGE_URL,
  });
};

export const getDownloadFilteredData = (filters) => {
  return axios({
    method: "GET",
    url: DOWNLOAD_PAGE_URL + "/GetFile",
    params: filters,
  });
};

// new api for filter
export const getDownloadFilterItems = () => {
  return axios({
    method: "GET",
    url: DOWNLOAD_PAGE_URL + "/GetFilterItem",
  });
};
