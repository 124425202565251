import React, { useEffect, useState } from "react";
import "./searchItems.scss";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

// icons
import { MdOutlineClose } from "react-icons/md";

// assets
import Logo from "../../../navbar/components/assets/tocoma-logo-update.png";
import pdfIconImg from "../assets/pdf_icon.svg";

// apis
import { getSearchResults } from "../../../../../apis/search.api";

// components
import Wrapper from "../../../../common/wrapper/Wrapper";
import Wrapper2 from "../../../wrapper2/Wrapper2";

const SearchItems = () => {
  // state
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleclose = () => {
    const close = document.querySelector(".navba__searchContainer");
    close.classList.remove("active");

    setSearchQuery("");
    setSearchResults([]);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      const results = await getSearchResults(searchQuery);
      setSearchResults(results.data);
    } catch (error) {
      console.error("Error fetching search results:", error);
    } finally {
      setLoading(false);
    }
  };

  // Debounce search input to prevent excessive API calls
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  return (
    <div className="nav__searchComponent">
      <div className="nsc__header">
        <Wrapper2>
          <div className="nsc__headerContainer">
            <div className="nsc__headerLeft">
              <Link to={"/"} className="nsc__logo">
                <img src={Logo} alt="" />
              </Link>

              <div className="nsc__searchInput">
                <input
                  type="text"
                  placeholder="Search.."
                  value={searchQuery}
                  onChange={handleSearchChange}
                />

                {/* <CiSearch className="nsc__searchIcon" onClick={handleSearch} /> */}
              </div>
            </div>

            <MdOutlineClose className="nsc__closeIcon" onClick={handleclose} />
          </div>
        </Wrapper2>
      </div>

      <div className="nsc__itemContainer1">
        <Wrapper>
          <div className="nsc__itemContainer">
            {loading && (
              <div className="nsc__loading">
                <div className="nsc__itemTitle">Loading...</div>
              </div>
            )}

            {searchResults.brandList && (
              <div className="general__itemContainer">
                <h3>Partner</h3>

                <div className="general__items">
                  {searchResults?.brandList.map((item, index) => (
                    <Link
                      to={"/partners/" + item.BRAND_ID}
                      className="general__itemsItem link"
                      key={index}
                      onClick={handleclose}
                    >
                      <div className="general__itemImg brand">
                        <img src={item?.LOGO} alt="" className="contain" />
                      </div>

                      <div className="general__itemInfos">
                        <span className="itemTitle">{item.BRAND_NAME}</span>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            )}

            {searchResults.categorylist && (
              <div className="general__itemContainer">
                <h3>Categoty</h3>

                <div className="general__items">
                  {searchResults?.categorylist.map((item, index) => (
                    <Link
                      to={"/products/list/2/" + item.CATEGORY_ID}
                      className="general__itemsItem link"
                      onClick={handleclose}
                      key={index}
                    >
                      <div className="general__itemImg category">
                        <img src={item?.CATEGORY_IMAGE} alt="" className="" />
                      </div>
                      <div className="general__itemInfos">
                        <span className="itemTitle">{item.CATEGORY_NAME}</span>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            )}

            {searchResults?.projects && (
              <div className="general__itemContainer">
                <h3>Projects</h3>

                <div className="general__items">
                  {searchResults?.projects.map((item, index) => (
                    <Link
                      to={`/reference/${item?.REF_PROJECT_TYPE_ID}/${item.REF_PROJECT_ID}`}
                      className="general__itemsItem link"
                      key={index}
                      onClick={handleclose}
                    >
                      <div className="general__itemImg project">
                        <img
                          src={item?.PROJECT_IMAGE}
                          alt=""
                          className="cover "
                        />
                      </div>

                      <div className="general__itemInfos">
                        <span className="itemTitle">{item.PROJECT_NAME}</span>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            )}

            {searchResults.itemlist && (
              <div className="general__itemContainer">
                <h3>Products</h3>
                <div className="general__items link">
                  {searchResults?.itemlist.map((item, index) => (
                    <Link
                      to={`/products/${item.CATEGORY_ID}/${item.ITEM_ID}`}
                      className="general__itemsItem link"
                      key={index}
                      onClick={handleclose}
                    >
                      <div className="general__itemImg common">
                        <img src={item?.ITEM_IMAGE} alt="" />
                      </div>

                      <div className="general__itemInfos">
                        <span className="itemTitle">{item.ITEM_NAME}</span>

                        {item.SHORT_DESCRIPTION && (
                          <span className="">
                            {parse(item.SHORT_DESCRIPTION)}
                          </span>
                        )}
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            )}

            {searchResults?.fileList && (
              <div className="general__itemContainer">
                <h3>Documents</h3>

                <div className="general__items">
                  {searchResults?.fileList.map((item, index) => (
                    <a
                      key={index}
                      className="general__itemsItem link"
                      href={item?.FILE_PATH}
                      target="_blank"
                      rel="noreferrer"
                      onClick={handleclose}
                    >
                      <div className="general__itemImg document">
                        {/* <img src={item?.ITEM_IMAGE} alt="" className="cover" /> */}
                        <img
                          src={pdfIconImg}
                          alt=""
                          className="contain opacity"
                        />
                      </div>

                      <div className="general__itemInfos">
                        <span className="itemTitle">{item.ITEM_NAME}</span>
                        <span>{item.FILE_TYPE}</span>
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            )}
          </div>
        </Wrapper>
      </div>
    </div>
  );
};

export default SearchItems;
