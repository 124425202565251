import React, { useState } from "react";
import "./aboutUs.scss";
import parse from "html-react-parser";

// icons
import {
  MdOutlineKeyboardArrowUp,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";

// collapsible component
import Collapsible from "react-collapsible";

const AboutUs = ({ isCollapsOpen, data }) => {
  const [isOpen, setIsOpen] = useState(isCollapsOpen || false);

  const handleCollapsible = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="aboutUs">
      <Collapsible
        open={isOpen}
        trigger={
          <div
            className="aboutPage__collapsible__trigger"
            onClick={handleCollapsible}
          >
            <h2>About Us</h2>

            <>
              {isOpen ? (
                <MdOutlineKeyboardArrowUp className="amv__collapsible__icon" />
              ) : (
                <MdOutlineKeyboardArrowDown className="amv__collapsible__icon" />
              )}
            </>
          </div>
        }
        // triggerWhenOpen="Our Mission"
        // triggerClassName="collapsible__trigger"
        // triggerOpenedClassName="collapsible__trigger--open"
        // contentOuterClassName="collapsible__contentOuter"
        // contentInnerClassName="collapsible__contentInner"
      >
        <div className="au__innerContainer">
          {data?.DESCRIPTION && (
            <span className="au__text">{parse(data?.DESCRIPTION)}</span>
          )}
        </div>
      </Collapsible>
    </div>
  );
};

export default AboutUs;
