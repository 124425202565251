import React, { useState } from "react";
import "./customerSolutions.scss";
import parse from "html-react-parser";

// icons
import {
  MdOutlineKeyboardArrowUp,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";

// collapsible component
import Collapsible from "react-collapsible";

// data
import { csData } from "./data";

const CustomerSolutions = ({ isCollapsOpen, data }) => {
  const [isOpen, setIsOpen] = useState(isCollapsOpen || false);

  const handleCollapsible = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="customerSolutions">
      <Collapsible
        open={isOpen}
        trigger={
          <div
            className="aboutPage__collapsible__trigger"
            onClick={handleCollapsible}
          >
            <h2>Customer Solutions</h2>

            <>
              {isOpen ? (
                <MdOutlineKeyboardArrowUp className="amv__collapsible__icon" />
              ) : (
                <MdOutlineKeyboardArrowDown className="amv__collapsible__icon" />
              )}
            </>
          </div>
        }
        // triggerWhenOpen="Our Mission"
        // triggerClassName="collapsible__trigger"
        // triggerOpenedClassName="collapsible__trigger--open"
        // contentOuterClassName="collapsible__contentOuter"
        // contentInnerClassName="collapsible__contentInner"
      >
        <div className="acs__innerContainer">
          {data?.DESCRIPTION && (
            <span className="acs__text">{parse(data?.DESCRIPTION)}</span>
          )}

          <div className="acs__itemList">
            {data &&
              data?.typeList?.map((item) => (
                <div key={item.id} className="acs__item">
                  <div className="acs__itemIcon">
                    <img src={item.IMAGE} alt="" />
                  </div>

                  <span>{item.DESCRIPTION}</span>
                </div>
              ))}
          </div>
        </div>
      </Collapsible>
    </div>
  );
};

export default CustomerSolutions;
