import React, { useEffect, useRef, useState } from "react";
import "./carousal.scss";
import parse from "html-react-parser";

// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper";

// import CarItemViewer from "./carousalItem/CarItemViewer";

// image
import yollowImage from "../../../assets/images/icons/Product Slider hober sign 02.png";
import blackImgae from "../../../assets/images/icons/Product Slider sign 01.png";

// api service
import { getLandingCarouselData } from "../../../apis/hero.apis";

export default function Carousal({ progressSlide, single }) {
  const swiperRef = useRef(null);
  const [autoplay, setAutoPlay] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);
  const [sliderData, setSliderData] = useState([]);

  useEffect(() => {
    getLandingCarouselData()
      .then((res) => {
        setSliderData(res.data);
        setTotalSlides(res.data.length); // Update total slides count here
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const pauseOrPlay = () => {
    if (autoplay) {
      swiperRef.current.swiper.autoplay.stop();
      setAutoPlay(false);
    } else {
      swiperRef.current.swiper.autoplay.start();
      setAutoPlay(true);
    }
  };

  // add carousel__textHeader animation class when slide change
  useEffect(() => {
    const carousel__textHeader = document.querySelector(
      ".carousel__textHeader"
    );

    const carousel__textDesc = document.querySelector(".carousel__textDesc");
    carousel__textHeader.classList.add("animate__animated");
    carousel__textDesc.classList.add("animate__animated");

    setTimeout(() => {
      carousel__textHeader.classList.remove("animate__animated");
      carousel__textDesc.classList.remove("animate__animated");
    }, 2000);
  }, [currentSlide]);

  return (
    <div className="carousal_test">
      <div className="carousel__container">
        <Swiper
          className="cariusel__swiper"
          modules={[Navigation, Pagination, Scrollbar, Autoplay]}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          speed={2500}
          loop={true}
          ref={swiperRef}
          onSlideChange={(swiper) => setCurrentSlide(swiper.realIndex)}
          onSwiper={(swiper) => setTotalSlides(swiper.slides.length)} // Update total slides count here

          // allowTouchMove={false}
        >
          {sliderData &&
            sliderData.map((item, index) => (
              <SwiperSlide key={index}>
                <img src={item?.SLIDER_IMAGE} alt="" />
              </SwiperSlide>
            ))}
        </Swiper>

        <div className="carousel__dummy">
          {progressSlide && (
            <div className="side_index">
              <div className="current_slide"> 0{currentSlide + 1}</div>

              <div className="it">
                {Array.from(Array(totalSlides).keys()).map((item, index) => (
                  <div
                    className="side_index_item"
                    onClick={() => {
                      swiperRef.current.swiper.slideTo(item);
                      setCurrentSlide(item);
                    }}
                    key={index}
                  >
                    <img
                      src={item === currentSlide ? yollowImage : blackImgae}
                      alt="side index"
                    />
                  </div>
                ))}
              </div>

              {autoplay ? (
                <div
                  className="play_pause"
                  onClick={() => {
                    pauseOrPlay();
                  }}
                >
                  <i className="fa-solid fa-pause"></i>
                </div>
              ) : (
                <div
                  className="play_pause"
                  onClick={() => {
                    pauseOrPlay();
                  }}
                >
                  <i className="fa-solid fa-play"></i>
                </div>
              )}
            </div>
          )}
        </div>

        <div className="carousel__textContainer">
          <div className="carousel__text">
            <div className="carousel__textDiv">
              <div className="carousel__textHeader animate__animated">
                {sliderData[currentSlide]?.IMAGE_TEXT}
              </div>

              <div className="carousel__textDesc animate__animated">
                {sliderData[currentSlide]?.DESCRIPTION &&
                  parse(sliderData[currentSlide]?.DESCRIPTION)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
