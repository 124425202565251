import React from "react";
import "./landingAppLayout.scss";

// components
import Navbar from "../../common/navbar/Navbar";

const LandingAppLayout = ({ children }) => {
  return (
    <div className="appLayout__landing">
      <Navbar />
      <main className="layout__child">{children}</main>
    </div>
  );
};

export default LandingAppLayout;
