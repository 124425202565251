import React from "react";
import "./errorPage.scss";

import ErrorImage from "../../assets/images/errorPage/errorPage.png";
import AppLayout from "../../components/layouts/appLayout/AppLayout";

const ErrorPage = () => {
  return (
    <AppLayout>
      <div className="errorPage">
        <div className="errorPage__container">
          <img src={ErrorImage} alt="" />
        </div>
      </div>
    </AppLayout>
  );
};

export default ErrorPage;
