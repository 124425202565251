import React, { useEffect, useState } from "react";
import "./landingExploreProduct.scss";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

// icons
import { MdArrowBackIosNew, MdArrowForwardIos } from "react-icons/md";

// Import Swiper styles
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/grid";
import { Grid, Pagination } from "swiper";

// apis
import { getLandingPageProductData } from "../../../apis/products.apis";

// components
import Wrapper from "../../../components/common/wrapper/Wrapper";
import LandingProductCard from "./landingProductCard/LandingProductCard";
import PrimaryBtn from "../../../components/buttons/primaryBtn/PrimaryBtn";
import ArrowBtn from "../../../components/buttons/arrowBtn/ArrowBtn";

const LandingExploreProduct = () => {
  const [exploreOurProducts, setExploreOurProducts] =
    useState("Concrete Additives");

  const [exploreProductData, setExploreProductData] = useState([]);
  useEffect(() => {
    getLandingPageProductData()
      .then((res) => {
        setExploreProductData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [catId, setCatId] = useState(0);
  const [linkId, setLinkId] = useState(1);
  const handleExploreOurProducts = (value, cat, passId) => {
    setExploreOurProducts(value);
    setCatId(cat);
    setLinkId(passId);
  };

  const [swiper, setSwiper] = useState(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const handleShiftLeft = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };
  const handleShiftRight = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };
  useEffect(() => {
    if (swiper) {
      swiper.on("slideChange", () => {
        if (swiper.isBeginning) {
          setIsBeginning(true);
          setIsEnd(false);
        } else if (swiper.isEnd) {
          setIsBeginning(false);
          setIsEnd(true);
        } else {
          setIsBeginning(false);
          setIsEnd(false);
        }
      });
    }
  }, [swiper]);

  const [swiper1, setSwiper1] = useState(null);
  const [isBeginning1, setIsBeginning1] = useState(true);
  const [isEnd1, setIsEnd1] = useState(false);
  const handleShiftLeft1 = () => {
    if (swiper1) {
      swiper1.slidePrev();
    }
  };
  const handleShiftRight1 = () => {
    if (swiper1) {
      swiper1.slideNext();
    }
  };
  useEffect(() => {
    if (swiper1) {
      swiper1.on("slideChange", () => {
        if (swiper1.isBeginning1) {
          setIsBeginning1(true);
          setIsEnd1(false);
        } else if (swiper1.isEnd1) {
          setIsBeginning1(false);
          setIsEnd1(true);
        } else {
          setIsBeginning1(false);
          setIsEnd1(false);
        }
      });
    }
  }, [swiper1]);

  return (
    <div className="landingExploreProduct">
      <Wrapper>
        <div className="landingExploreProduct__container">
          <div className="homeSection__headerDesc">
            <span className="homeSection__headerText">Our Products</span>
          </div>

          <div className="lpp__swiperBtncontainer">
            <div
              className={
                isBeginning === true
                  ? "lpp__slideBtn left"
                  : "lpp__slideBtn left"
              }
              onClick={handleShiftLeft}
            >
              <i className="fa-solid fa-chevron-left sicon" />
            </div>

            <Swiper
              className="lpp__swiperBtns"
              slidesPerView={4}
              spaceBetween={20}
              onSwiper={setSwiper}
              breakpoints={{
                0: {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                768: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
              }}
            >
              {exploreProductData?.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div
                      className={
                        exploreOurProducts === item.CATEGORY_NAME
                          ? "lpp__filterBtn activeBtn"
                          : "lpp__filterBtn"
                      }
                      onClick={() =>
                        handleExploreOurProducts(
                          item.CATEGORY_NAME,
                          index,
                          item.CATEGORY_ID
                        )
                      }
                    >
                      <div className="lpp__filterBtn__text">
                        {item.CATEGORY_NAME}
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>

            <div
              className={
                isEnd === true ? "lpp__slideBtn right" : "lpp__slideBtn right"
              }
              onClick={handleShiftRight}
            >
              <i className="fa-solid fa-chevron-right sicon" />
            </div>
          </div>

          <div className="lep__gridSwiper">
            <Swiper
              className="landingExploreProduct__swiper"
              slidesPerView={2}
              spaceBetween={30}
              grid={{
                rows: 2,
                fill: "row",
              }}
              modules={[Grid, Pagination]}
              onSwiper={setSwiper1}
            >
              {exploreProductData[catId]?.productList?.map((slide, index) => {
                return (
                  <SwiperSlide
                    className="landingExploreProduct__containerItem"
                    key={index}
                  >
                    <LandingProductCard
                      data={slide}
                      link={`/products/${slide.CATEGORY_ID}/${slide.ITEM_ID}`}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>

          <div className="landingExploreProduct__bottom">
            <div className="landingExploreProduct__bottomLeft">
              <div onClick={handleShiftLeft1}>
                <ArrowBtn left={true} isBeginning1={isBeginning1} />
              </div>

              <div onClick={handleShiftRight1}>
                <ArrowBtn left={false} isEnd1={isEnd1} />
              </div>
            </div>

            <div className="landingExploreProduct__bottomRight">
              <Link to="/products" state={2} className="link">
                <PrimaryBtn
                  link={"/products"}
                  // name={"Explore All"}
                  name={"View All Products"}
                  color={"black"}
                  size={"large"}
                />
              </Link>
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default LandingExploreProduct;
