import React, { useEffect, useRef, useState } from "react";
import "./productDetailsPage.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import parse from "html-react-parser";

// icons
import { GrDocumentPdf } from "react-icons/gr";
import pdfIcon from "./assets/pdf_icon.svg";

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  // A11y,
  Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

// static data
import { btnData } from "./btnData";

// api imports
import { getProductsData } from "../../../apis/products.apis";
import { getProductCarouselData } from "../../../apis/hero.apis";

// components
import Wrapper from "../../../components/common/wrapper/Wrapper";
import ProductCard from "../../../components/cards/productCard/ProductCard";
import SubHero from "../../../components-ui/heroSections/subHero/SubHero";
import AppLayout from "../../../components/layouts/appLayout/AppLayout";

export default function ProductDetail() {
  const navigate = useNavigate();
  SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay]);

  const catId = useParams().productCatId;
  const pId = useParams().productId;

  const [slideData, setSlideData] = useState([]);
  useEffect(() => {
    // slide data
    getProductCarouselData()
      .then((res) => {
        setSlideData(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [data, setData] = useState({});
  useEffect(() => {
    getProductsData({ catId, pId })
      .then((res) => {
        setData(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [catId, pId]);

  const [selectedOption, setSelectedOption] = useState("overview");
  const relatedProductData = [
    {
      id: 1,
      ITEM_IMAGE:
        "https://www.tocoma.co/images/Products/SKU-11-0001-CM_Denka%20Power%20CSA%20T-R.jpg",
      ITEM_NAME: "Denka Power CSA T-R",
      SHORT_DESCRIPTION: "Shrinkage-Reducing and Compensating Additive",
    },
    {
      id: 2,
      ITEM_IMAGE:
        "https://www.tocoma.co/images/Products/SKU-11-0001-CM_Denka%20Power%20CSA%20T-R.jpg",
      ITEM_NAME: "Denka Power CSA T-R",
      desc: "Shrinkage-Reducing and Compensating Additive",
    },
    {
      id: 3,
      ITEM_IMAGE:
        "https://www.tocoma.co/images/Products/SKU-11-0001-CM_Denka%20Power%20CSA%20T-R.jpg",
      ITEM_NAME: "Denka Power CSA T-R",
      SHORT_DESCRIPTION: "Shrinkage-Reducing and Compensating Additive",
    },
    {
      id: 4,
      ITEM_IMAGE:
        "https://www.tocoma.co/images/Products/SKU-11-0001-CM_Denka%20Power%20CSA%20T-R.jpg",
      ITEM_NAME: "Denka Power CSA T-R",
      SHORT_DESCRIPTION: "Shrinkage-Reducing and Compensating Additive",
    },
  ];

  //update selectedOption
  const updateSelectedOption = (option) => {
    setSelectedOption(option);
  };

  const [numImg, setNumImg] = useState(1);
  const swiperRef = useRef(null);

  const changeNumImg = () => {
    setNumImg(swiperRef.current.swiper.realIndex + 1);
  };

  return (
    <AppLayout>
      <div className="productDetailsPage">
        <SubHero title={"Product Details"} />

        <Wrapper>
          <div className="common__headerLink__component">
            <Link to="/" className="common__headerLinkContainer">
              <span className="linkText">Home</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <Link to="/products" className="common__headerLinkContainer">
              <span className="linkText">Product</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <div
              className="common__headerLinkContainer"
              onClick={() => navigate(-1)}
            >
              <span className="linkText">
                {data?.CATEGORY_NAME ||
                  data?.BRAND_NAME ||
                  data?.APPLICATION_AREA}{" "}
              </span>
              <i className="fa-solid fa-angle-right"></i>
            </div>

            <Link
              to=""
              className="common__headerLinkContainer common__headerLinkContainer__active"
            >
              <span className="linkText">Product Details</span>
            </Link>
          </div>

          <div className="productDetailsPage__container">
            <div className="productDetailsContainer__block1">
              <div className="productDetailsContainerTop ">
                <h1>{data?.ITEM_NAME}</h1>

                {data.SHORT_DESCRIPTION && parse(data.SHORT_DESCRIPTION)}
              </div>

              <div className="productDetailsContainerBottom">
                <div className="prodLeft">
                  <Swiper
                    className="prodImgSwiper"
                    spaceBetween={0}
                    slidesPerView={1}
                    navigation
                    ref={swiperRef}
                    loop={true}
                    pagination={{ clickable: true }}
                    // scrollbar={{ draggable: true }}
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => changeNumImg()}
                  >
                    {/* {productDetails.images.map((image) => (
                  <SwiperSlide>
                    <img src={image.img} alt="" className="prodImg" />
                  </SwiperSlide>
                ))} */}
                    <SwiperSlide className="prodImg">
                      <img src={data?.ITEM_IMAGE} alt="" />
                    </SwiperSlide>
                  </Swiper>
                  {/* <span className="prodImgCount">
                {numImg} / {productDetails.images.length}
              </span> */}
                </div>

                <div className="prodRight">
                  <div className="prodRightTop__btns">
                    {btnData.map((item, i) => {
                      return (
                        <div
                          className={
                            selectedOption === item.slug
                              ? "prodRightTop__btn active"
                              : "prodRightTop__btn"
                          }
                          onClick={() => updateSelectedOption(item.slug)}
                          key={i}
                        >
                          <div className="prodRightTop__text">
                            <span>{item.title}</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  <div className="prodRightBottom">
                    {selectedOption === "overview" ? (
                      <div className="prodRightBottom__overView">
                        <div className="overViewDesc">
                          {data?.LONG_DESCRIPTION &&
                            parse(data?.LONG_DESCRIPTION)}
                        </div>

                        <div className="aditional">
                          {data?.packsize && (
                            <div className="ad__list">
                              <span className="listTitle">Pack Size</span>

                              <ul className="ad__listItems">
                                {data?.packsize.map((item) => {
                                  return (
                                    <li className="ad__listItem">{item}</li>
                                  );
                                })}
                              </ul>
                            </div>
                          )}

                          {data?.shelfLife && (
                            <div className="ad__list">
                              <span className="listTitle">Shelf Life</span>
                              <ul className="ad__listItems">
                                {data?.shelfLife.map((item) => {
                                  return (
                                    <li className="ad__listItem">{item}</li>
                                  );
                                })}
                              </ul>
                            </div>
                          )}

                          {data?.dosage_coveragelist && (
                            <div className="ad__list">
                              <span className="listTitle">Dosage Coverage</span>
                              <ul className="ad__listItems">
                                {data?.dosage_coveragelist.map((item) => {
                                  return (
                                    <li className="ad__listItem">{item}</li>
                                  );
                                })}
                              </ul>
                            </div>
                          )}

                          {data?.color && (
                            <div className="ad__list">
                              <span className="listTitle">Color</span>
                              <ul className="ad__listItems">
                                {data?.color.map((item) => {
                                  return (
                                    <li className="ad__listItem">{item}</li>
                                  );
                                })}
                              </ul>
                            </div>
                          )}

                          {data?.densitylist && (
                            <div className="ad__list">
                              <span className="listTitle">Density</span>
                              <ul className="ad__listItems">
                                {data?.densitylist.map((item) => {
                                  return (
                                    <li className="ad__listItem">{item}</li>
                                  );
                                })}
                              </ul>
                            </div>
                          )}

                          {data?.product_appearancelist && (
                            <div className="ad__list">
                              <span className="listTitle">
                                Product Appearance
                              </span>
                              <ul className="ad__listItems">
                                {data?.product_appearancelist.map((item) => {
                                  return (
                                    <li className="ad__listItem">{item}</li>
                                  );
                                })}
                              </ul>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : selectedOption === "advantage" ? (
                      <div className="prodRightBottomDesc">
                        {data?.ADVANTAGE && parse(data?.ADVANTAGE)}
                      </div>
                    ) : selectedOption === "application-area" ? (
                      <div className="prodRightBottom__area">
                        {data?.APPLICATION_AREA &&
                          parse(data?.APPLICATION_AREA)}
                      </div>
                    ) : selectedOption === "precaution" ? (
                      <div className="prodRightBottomDesc">
                        {data?.SEWSTIVENESS && parse(data?.SEWSTIVENESS)}
                      </div>
                    ) : selectedOption === "product-documents" ? (
                      <div className="prodRightBottomDesc">
                        <div className="prodRightBottom__documents">
                          {data.fileList && data.fileList.length > 0
                            ? data.fileList.map((item) => {
                                return (
                                  <a
                                    href={item.FilePath}
                                    target="_blank"
                                    className="prodRightBottom__docLink link"
                                  >
                                    <img
                                      src={pdfIcon}
                                      alt=""
                                      className="prodRightBottomDescItem__icon"
                                    />

                                    <span className="prodRightBottomDescItem__text">
                                      {item?.FileName}
                                    </span>
                                  </a>
                                );
                              })
                            : null}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>

            {data?.relatedItems && (
              <div className="productPage__relatedProductApplication">
                <div className="relatedProductApplication__Header">
                  <h1 className="relatedProductApplication__HeaderText">
                    Related Products
                  </h1>
                </div>

                <div className="relatedProductApplication__container">
                  {(data?.relatedItems &&
                    data?.relatedItems?.map((item, i) => {
                      return (
                        <ProductCard
                          key={i}
                          link={`/products/${item.CATEGORY_ID}/${item.ITEM_ID}`}
                          data={item}
                          btnName={"View Details"}
                        />
                      );
                    })) || (
                    <div className="noRelatedData">
                      <span>No Related Products Found.!!</span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </Wrapper>
      </div>
    </AppLayout>
  );
}
