import React, { useEffect, useState } from "react";
import "./partnersPage.scss";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

// api import
import { getSubHeadersData } from "../../../apis/subHeader.apis";
import { getOurPartnerData } from "../../../apis/ourPartners.apis";

// components import
import Wrapper from "../../../components/common/wrapper/Wrapper";
import SubHero from "../../../components-ui/heroSections/subHero/SubHero";
import AppLayout from "../../../components/layouts/appLayout/AppLayout";
import PartnerCard from "../../../components/cards/partnerCard/PartnerCard";

const PartnersPage = () => {
  // states
  const [subHeaderData, setSubHeaderData] = useState([]);
  const [ourPartners, setOurPartners] = useState([]);

  useEffect(() => {
    // sub header
    getSubHeadersData()
      .then((res) => {
        const data = res.data.filter((item) => item.PAGE_SHORT_NAME === "OP");
        setSubHeaderData(data);
      })
      .catch((err) => {
        console.log(err);
      });

    getOurPartnerData()
      .then((res) => {
        setOurPartners(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // console.log(ourPartners);

  return (
    <AppLayout>
      <div id="landingPage">
        <SubHero
          title={subHeaderData && subHeaderData[0]?.TITLE}
          desc={subHeaderData && subHeaderData[0]?.DESCRIPTION}
        />

        <Wrapper>
          <div className="common__headerLink__component">
            <Link to="/" className="common__headerLinkContainer">
              <span className="linkText">Home</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <Link
              to="/partners"
              className="common__headerLinkContainer common__headerLinkContainer__active"
            >
              <span className="linkText">Partners</span>
            </Link>
          </div>

          <div className="partners__container">
            {ourPartners?.map((item, index) => (
              <PartnerCard
                key={index}
                data={item}
                link={`/partners/${item.BRAND_ID}`}
              />
            ))}
          </div>
        </Wrapper>
      </div>
    </AppLayout>
  );
};

export default PartnersPage;
