import React from "react";
import "./landingPage.scss";

import { useEffect, useRef, useState } from "react";

// data
import { rightNavigationData } from "./data";

// component impoet
import Footer from "../../components/common/footer/Footer";
import Carousal from "../../components/commonPageComponent/carousel1/Carousal";

// components
import LandingReferenceProject from "./landingReferenceProject/LandingReferenceProject";
import LandingPartners from "./landingPartners/LandingPartners";
import LandingCareer from "./landingCareer/LandingCareer";
import Wrapper from "../../components/common/wrapper/Wrapper";
import LandingClient from "./landingClient/LandingClient";
import HomeAbout from "./langingAbout/LandingAbout";
import LandingExploreProduct from "./landingExploreProduct/LandingExploreProduct";
import LandingTargetMarket from "./landingTargetMarket1/LandingTargetMarket";
import AppLayout from "../../components/layouts/landingAppLayout/LandingAppLayout";

function LandingPage2() {
  const [currentSlide, setCurrentSlide] = useState(null);
  const myContainerRef = useRef(null);
  const myElementRef1 = useRef([null]);
  const myElementRef2 = useRef([null]);
  const myElementRef3 = useRef([null]);
  const myElementRef4 = useRef([null]);
  const myElementRef5 = useRef([null]);
  const myElementRef6 = useRef([null]);
  const myElementRef7 = useRef([null]);
  const myElementRef8 = useRef([null]);
  const myElementRef9 = useRef([null]);
  const myElementRef10 = useRef([null]);

  const [refScrollPosition, setRefScrollPosition] = useState(1);
  const handleScrollLeft = () => {
    setRefScrollPosition(myContainerRef.current.scrollTop);
    // console.log(refScrollPosition);
  };
  const goToNextRef = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  const handleSetCurrentSlide = (index) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  //use Effect
  useEffect(() => {
    if (refScrollPosition < 728) {
      if (currentSlide) {
        handleSetCurrentSlide(1);
      }
    } else if (refScrollPosition > 728 && refScrollPosition < 1487) {
      handleSetCurrentSlide(2);
    } else if (refScrollPosition > 1487 && refScrollPosition < 2246) {
      handleSetCurrentSlide(3);
    } else if (refScrollPosition > 2246 && refScrollPosition < 3005) {
      handleSetCurrentSlide(4);
    } else if (refScrollPosition > 3005 && refScrollPosition < 3766) {
      handleSetCurrentSlide(5);
    } else if (refScrollPosition > 3766 && refScrollPosition < 4315) {
      handleSetCurrentSlide(6);
    } else if (refScrollPosition > 4315 && refScrollPosition < 5000) {
      handleSetCurrentSlide(7);
    } else {
      handleSetCurrentSlide(8);
    }

    // eslint-disable-next-line
  }, [
    refScrollPosition,
    currentSlide,
    myElementRef1,
    myElementRef2,
    myElementRef3,
    myElementRef4,
    myElementRef5,
    myElementRef6,
    myElementRef7,
    myElementRef8,
    myElementRef9,
    myElementRef10,
    myContainerRef,
    handleSetCurrentSlide,
  ]);

  return (
    <AppLayout>
      <div className="landing_screen_container_full">
        <div
          ref={myContainerRef}
          className="landing_screen_container"
          onScroll={handleScrollLeft}
        >
          <div className="section1" ref={myElementRef1}>
            <Carousal progressSlide={true} />
          </div>

          <div className="section2" ref={myElementRef2}>
            <HomeAbout />
          </div>

          <div className="section3" ref={myElementRef3}>
            <LandingTargetMarket />
          </div>

          <div className="section4" ref={myElementRef4}>
            <LandingExploreProduct />
          </div>

          <div className="section5" ref={myElementRef5}>
            <LandingReferenceProject />
          </div>

          <div className="section6" ref={myElementRef6}>
            <LandingPartners />
          </div>

          <div className="section7" ref={myElementRef7}>
            <LandingCareer />
          </div>

          <div className="sectionFooter" ref={myElementRef8}>
            <div className="landing__clientFooter">
              <Wrapper>
                <LandingClient />
              </Wrapper>

              <Footer />
            </div>
          </div>
        </div>

        <div className="right_navigation">
          <div className="right_navigation_container">
            {/* {rightNavigationData.map((item) => (
              <div
                key={item.id}
                className={
                  currentSlide === item.id
                    ? "right_navigation_container_holder yollowLeft"
                    : "right_navigation_container_holder"
                }
              >
                <div
                  className={
                    currentSlide === null
                      ? "right_navigation_item"
                      : currentSlide === item.id
                      ? "right_navigation_item active"
                      : "right_navigation_item inactive"
                  }
                  onClick={() => {
                    goToNextRef(item.ref);
                  }}
                >
                  {item.title}
                </div>
              </div>
            ))} */}

            <div
              className={
                currentSlide === 1
                  ? "right_navigation_container_holder yollowLeft"
                  : "right_navigation_container_holder"
              }
            >
              <div
                className={
                  currentSlide === null
                    ? "right_navigation_item"
                    : currentSlide === 1
                    ? "right_navigation_item active"
                    : "right_navigation_item inactive"
                }
                onClick={() => {
                  goToNextRef(myElementRef1);
                }}
              >
                Top
              </div>
            </div>

            <div
              className={
                currentSlide === 2
                  ? "right_navigation_container_holder yollowLeft"
                  : "right_navigation_container_holder"
              }
            >
              {" "}
              <div
                className={
                  currentSlide === null
                    ? "right_navigation_item "
                    : currentSlide === 2
                    ? "right_navigation_item active"
                    : "right_navigation_item inactive"
                }
                onClick={() => {
                  goToNextRef(myElementRef2);
                }}
              >
                About us
              </div>
            </div>

            <div
              className={
                currentSlide === 3
                  ? "right_navigation_container_holder yollowLeft"
                  : "right_navigation_container_holder"
              }
            >
              {" "}
              <div
                className={
                  currentSlide === null
                    ? "right_navigation_item "
                    : currentSlide === 3
                    ? "right_navigation_item active"
                    : "right_navigation_item inactive"
                }
                onClick={() => {
                  goToNextRef(myElementRef3);
                }}
              >
                Target Market
              </div>
            </div>

            <div
              className={
                currentSlide === 4
                  ? "right_navigation_container_holder yollowLeft"
                  : "right_navigation_container_holder"
              }
            >
              {" "}
              <div
                className={
                  currentSlide === null
                    ? "right_navigation_item "
                    : currentSlide === 4
                    ? "right_navigation_item active"
                    : "right_navigation_item inactive"
                }
                onClick={() => {
                  goToNextRef(myElementRef4);
                }}
              >
                Products
              </div>
            </div>

            <div
              className={
                currentSlide === 5
                  ? "right_navigation_container_holder yollowLeft"
                  : "right_navigation_container_holder"
              }
            >
              {" "}
              <div
                className={
                  currentSlide === null
                    ? "right_navigation_item "
                    : currentSlide === 5
                    ? "right_navigation_item active"
                    : "right_navigation_item inactive"
                }
                onClick={() => {
                  goToNextRef(myElementRef5);
                }}
              >
                Reference
              </div>
            </div>

            <div
              className={
                currentSlide === 6
                  ? "right_navigation_container_holder yollowLeft"
                  : "right_navigation_container_holder"
              }
            >
              {" "}
              <div
                className={
                  currentSlide === null
                    ? "right_navigation_item "
                    : currentSlide === 6
                    ? "right_navigation_item active"
                    : "right_navigation_item inactive"
                }
                onClick={() => {
                  goToNextRef(myElementRef6);
                }}
              >
                Partners
              </div>
            </div>

            <div
              className={
                currentSlide === 7
                  ? "right_navigation_container_holder yollowLeft"
                  : "right_navigation_container_holder"
              }
            >
              <div
                className={
                  currentSlide === null
                    ? "right_navigation_item "
                    : currentSlide === 7
                    ? "right_navigation_item active"
                    : "right_navigation_item inactive"
                }
                onClick={() => {
                  goToNextRef(myElementRef7);
                }}
              >
                Career
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}

export default LandingPage2;
