import React from "react";
import "./appLayout.scss";

// components
import Navbar from "../../common/navbar/Navbar";
import Footer from "../../common/footer/Footer";

const AppLayout = ({ children }) => {
  return (
    <div className="appLayout">
      <Navbar />

      <main className="layout__child">{children}</main>

      <Footer />
    </div>
  );
};

export default AppLayout;
