import React from "react";
import "./landingProductCard.scss";
import { Link } from "react-router-dom";
import parser from "html-react-parser";

// components
import PrimaryBtn from "../../../../components/buttons/primaryBtn/PrimaryBtn";

const LandingProductCard = ({ data, link, btnName }) => {
  return (
    <Link to={link} className="landingProductCard link">
      <div className="productCard__Img">
        <img src={data?.ITEM_IMAGE} alt="" />
      </div>

      <div className="productCard__Content">
        <div className="gridCard__nameDesc">
          <span className="productCard__catName">{data?.ITEM_NAME}</span>
          <div className="productCard__catDesc">
            {data?.SHORT_DESCRIPTION && parser(data?.SHORT_DESCRIPTION)}
          </div>
        </div>

        <div className="productCard__BtnHolder">
          <PrimaryBtn link={link} color={"gray"} size={"small"} />
        </div>
      </div>
    </Link>
  );
};

export default LandingProductCard;
