import React, { useEffect, useState } from "react";
import "./landingClient.scss";

// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import SwiperCore, { Autoplay } from "swiper";

// api service
import { getLandingClients } from "../../../apis/client.apis";

const LandingClient = () => {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    getLandingClients()
      .then((res) => {
        setClients(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="ourClientsList">
      <div className="ourClientList__headerDesc">
        <h3>Our Clients</h3>
      </div>

      <Swiper
        className="client__swiper"
        slidesPerView={4}
        spaceBetween={20}
        modules={[Autoplay]}
        autoplay={{
          delay: 800,
          disableOnInteraction: false,
        }}
        speed={2000}
        loop={true}
      >
        {clients &&
          clients?.map((client, index) => {
            return (
              <SwiperSlide key={index} className="client__swiperSlide">
                <img src={client?.CLIENT_LOGO} alt="" />
              </SwiperSlide>
            );
          })}
      </Swiper>
    </div>
  );
};

export default LandingClient;
