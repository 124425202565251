import React from "react";
import "./clientListPage.scss";
import { Link } from "react-router-dom";

// components
import Carousal from "../../components/commonPageComponent/carousel1/Carousal";
import Wrapper from "../../components/common/wrapper/Wrapper";

const ClientListPage = () => {
  return (
    <div className="clientListPage">
      <div className="topMask" />
      <Carousal />

      <Wrapper>
        <div className="common__headerLink__component">
          <Link to="/" className="common__headerLinkContainer">
            <span className="linkText">Home</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <div className="common__headerLinkContainer common__headerLinkContainer__active">
            <span className="linkText">Our Clients</span>
            <i className="fa-solid fa-angle-right"></i>
          </div>
        </div>

        <div className="clientListPage"></div>
      </Wrapper>
    </div>
  );
};

export default ClientListPage;
