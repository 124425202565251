import React, { useEffect, useState } from "react";
import "./workWithUs.scss";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

import c from "./assets/career.jpg";

// api calls import
import { getSubHeadersData } from "../../../apis/subHeader.apis";
import {
  getWorkWithUsData,
  getCareerPageData,
} from "../../../apis/workWithUs.apis";
import { getAboutData } from "../../../apis/aboutTocoma.apis";

// components import
import SubHero from "../../../components-ui/heroSections/subHero/SubHero";
import AppLayout from "../../../components/layouts/appLayout/AppLayout";
import Wrapper from "../../../components/common/wrapper/Wrapper";
import AboutMissionVission from "../../aboutPage/aboutPage/components/collapsibles/aboutMissionVission/AboutMissionVission";
import AboutCoreValues from "../../aboutPage/aboutPage/components/collapsibles/aboutCoreValues/AboutCoreValues";
import BusinessPrinciples from "../../aboutPage/aboutPage/components/collapsibles/businessPrinciples/BusinessPrinciples";
import AboutUs from "../../aboutPage/aboutPage/components/collapsibles/aboutUs/AboutUs";
import PrimaryBtn from "../../../components/buttons/primaryBtn/PrimaryBtn";

export default function WorkWithUs() {
  // states
  const [subHeaderData, setSubHeaderData] = useState([]);
  const [careerPageData, setCareerPageData] = useState([]);
  const [workWithUsData, setWorkWithUsData] = useState([]);
  const [aboutData, setAboutData] = useState([]);
  const [aboutUsData, setAboutUsData] = useState([]);
  const [missionData, setMissionData] = useState([]);
  const [vissionData, setVissionData] = useState([]);
  const [coreValuesData, setCoreValuesData] = useState([]);
  const [businessPrinciplesData, setBusinessPrinciplesData] = useState([]);

  useEffect(() => {
    // sub header
    getSubHeadersData()
      .then((res) => {
        const data = res.data.filter((item) => item.PAGE_SHORT_NAME === "CR");
        setSubHeaderData(data);
      })
      .catch((err) => {
        console.log(err);
      });

    getCareerPageData()
      .then((res) => {
        setCareerPageData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    getWorkWithUsData()
      .then((res) => {
        setWorkWithUsData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    getAboutData()
      .then((res) => {
        setAboutData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    // filter data and set to state
    aboutData &&
      aboutData.map((data) => {
        if (data?.BANNER_TITLE === "Mission") {
          setMissionData(data);
        } else if (data?.BANNER_TITLE === "Vision") {
          setVissionData(data);
        } else if (data?.BANNER_TITLE === "Core Values") {
          setCoreValuesData(data);
        } else if (data?.BANNER_TITLE === "Business Principles") {
          setBusinessPrinciplesData(data);
        } else if (data?.BANNER_TITLE === "About us") {
          setAboutUsData(data);
        }
      });
  }, [aboutData]);

  return (
    <AppLayout>
      <div className="globalPageLayout">
        <SubHero
          title={subHeaderData && subHeaderData[0]?.TITLE}
          desc={subHeaderData && subHeaderData[0]?.DESCRIPTION}
        />

        <Wrapper>
          <div className="pageSection__container workWithUs">
            <div className="common__headerLink__component">
              <Link to="/" className="common__headerLinkContainer">
                <span className="linkText">Home</span>
                <i className="fa-solid fa-angle-right"></i>
              </Link>

              <div className="common__headerLinkContainer common__headerLinkContainer__active">
                <span className="linkText">Career</span>
              </div>
            </div>

            <div className="whyChooseUs">
              <h2 className="title">{careerPageData?.TITLE_1}</h2>

              <div className="whyChooseUs__cards">
                <div className="whyChooseUs__card">
                  <img src={careerPageData?.BANNER_1} alt="whyUsIcon" />

                  <div className="cardContent">
                    {careerPageData?.BANNER_DESCRIPTION_1 &&
                      parse(careerPageData?.BANNER_DESCRIPTION_1)}

                    {/* <h3 className="cardTitle">
                      {careerPageData?.BANNER_DESCRIPTION_1}
                    </h3> */}
                    {/* <p className="cardDescription">{card.description}</p> */}
                  </div>
                </div>

                <div className="whyChooseUs__card">
                  <img src={careerPageData?.BANNER_2} alt="whyUsIcon" />

                  <div className="cardContent">
                    {careerPageData?.BANNER_DESCRIPTION_2 &&
                      parse(careerPageData?.BANNER_DESCRIPTION_2)}
                  </div>
                </div>

                <div className="whyChooseUs__card">
                  <img src={careerPageData?.BANNER_3} alt="whyUsIcon" />

                  <div className="cardContent">
                    {careerPageData?.BANNER_DESCRIPTION_3 &&
                      parse(careerPageData?.BANNER_DESCRIPTION_3)}
                  </div>
                </div>
              </div>
            </div>

            <div className="companyBenefits">
              <div className="headerDesc">
                <h2 className="title">{careerPageData?.TITLE_2}</h2>

                <p className="description">
                  {careerPageData?.DESCRIPTION &&
                    parse(careerPageData?.DESCRIPTION)}
                </p>
              </div>

              <div className="benefits__container">
                <div className="left">
                  <img src={c} alt="benefitsBg" />
                </div>

                <div className="right">
                  {careerPageData?.benefitList?.map((benefit) => (
                    <div key={benefit.id} className="benefit__point">
                      <div className="bullet"></div>
                      <span className="benefitTitle">{benefit}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="companyOverView">
              <h2 className="title">Company Overview</h2>

              <div className="overview__container">
                <AboutUs data={aboutUsData} />
                <AboutMissionVission
                  missionData={missionData}
                  vissionData={vissionData}
                />
                <AboutCoreValues data={coreValuesData} />
                <BusinessPrinciples data={businessPrinciplesData} />
              </div>
            </div>

            <div className="workWithUs__container">
              <div className="wwu__div">
                <div className="left">
                  <img src={careerPageData?.FOOTER_BANNER} alt="wwuBg" />
                </div>

                <div className="right">
                  <div className="headerDesc">
                    <h2 className="title">{careerPageData?.FOOTER_TITLE}</h2>
                    {careerPageData?.FOOTER_DESCRIPTION &&
                      parse(careerPageData?.FOOTER_DESCRIPTION)}
                  </div>

                  <div className="ctaBtn">
                    <PrimaryBtn
                      link={"/career/career-list"}
                      name={careerPageData?.ARROW_TEXT}
                      size={"medium"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
    </AppLayout>
  );
}
