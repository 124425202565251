import React, { useState } from "react";
import "./collapsiblePositions.scss";

import {
  MdOutlineKeyboardArrowUp,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";

// collapsible component
import Collapsible from "react-collapsible";
import PrimaryBtn from "../../../../../components/buttons/primaryBtn/PrimaryBtn";

const CollapsiblePositions = ({ isCollapsOpen, data }) => {
  const [isOpen, setIsOpen] = useState(isCollapsOpen || false);

  const handleCollapsible = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="collapsiblePositions">
      <Collapsible
        open={isOpen}
        trigger={
          <div className="op__collapsible__trigger" onClick={handleCollapsible}>
            <h2>{data?.DEPARTMENT_NAME}</h2>

            <div className="collapsible__iconText">
              {isOpen ? null : (
                <div className="positionCount">
                  <span>Open Position: {data?.positionList?.length}</span>
                </div>
              )}

              {isOpen ? (
                <MdOutlineKeyboardArrowUp className="collapsible__icon" />
              ) : (
                <MdOutlineKeyboardArrowDown className="collapsible__icon" />
              )}
            </div>
          </div>
        }
      >
        <div className="op__innerContainer">
          {data?.positionList &&
            data?.positionList.map((position, index) => {
              return (
                <div className="op__position" key={index}>
                  <div className="op__positionInfo">
                    <h3>{position.POSITION}</h3>

                    <div className="op__positionInfoList">
                      <div className="op__positionInfoRow">
                        <span>Experiance</span>
                        <span>: {position?.EXPERIANCE}</span>
                      </div>

                      <div className="op__positionInfoRow">
                        <span>Job Type</span>
                        <span>: {position?.EMPLOYMENT_STATUS}</span>
                      </div>

                      <div className="op__positionInfoRow">
                        <span>Contact Type</span>
                        <span>: {position?.CONTRACT_TYPE}</span>
                      </div>

                      <div className="op__positionInfoRow">
                        <span>Location</span>
                        <span>: {position?.JOB_LOCATION}</span>
                      </div>
                    </div>
                  </div>

                  <div className="op__button">
                    <PrimaryBtn
                      name={"View Details"}
                      size={"small"}
                      color={"blackGray"}
                      link={`/career/${position?.JOB_DESCRIPTION_ID}`}
                    />
                  </div>
                </div>
              );
            })}
        </div>
      </Collapsible>
    </div>
  );
};

export default CollapsiblePositions;
