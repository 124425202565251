import React, { useEffect, useState } from "react";
import "./productListPage.scss";
import { Link, useLocation, useParams } from "react-router-dom";

// apis
import { getProductCarouselData } from "../../../apis/hero.apis";
import { getProductsCatData } from "../../../apis/products.apis";

// components
import Wrapper from "../../../components/common/wrapper/Wrapper";
import CollapsibleComponent from "../../../components/collapsible/CollapsibleComponent";
import SubHero from "../../../components-ui/heroSections/subHero/SubHero";
import AppLayout from "../../../components/layouts/appLayout/AppLayout";

export default function ProductList() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  // const catId = window.location.pathname.split("/")[2];
  const { productCatId, stateId } = useParams();
  const [productCatData, setProductCatData] = useState([]);
  useEffect(() => {
    setLoading(true);
    getProductsCatData({ stateId, productCatId })
      .then((res) => {
        setProductCatData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [stateId, productCatId]);

  const [slideData, setSlideData] = useState([]);
  useEffect(() => {
    // slide data
    getProductCarouselData()
      .then((res) => {
        setSlideData(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <AppLayout>
      <div className="productListPage">
        <SubHero
          title={
            productCatData?.CATEGORY_NAME ||
            productCatData?.BRAND_NAME ||
            productCatData?.APPLICATION_AREA
          }
          desc={productCatData?.DESCRIPTION}
          // desc={"sada"}
        />

        <Wrapper>
          <div className="common__headerLink__component">
            <Link to="/" className="common__headerLinkContainer">
              <span className="linkText">Home</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <Link to="/products" className="common__headerLinkContainer">
              <span className="linkText">Products</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <div className="common__headerLinkContainer common__headerLinkContainer__active">
              <span className="linkText">
                {productCatData?.CATEGORY_NAME ||
                  productCatData?.BRAND_NAME ||
                  productCatData?.APPLICATION_AREA}{" "}
              </span>
            </div>
          </div>

          <div className="productListPage__container">
            {productCatData?.productList?.length > 0 ? (
              <CollapsibleComponent
                productListData={productCatData}
                id={productCatId}
                isCollapsOpen={true}
              />
            ) : (
              productCatData?.subSegmentList &&
              productCatData?.subSegmentList?.map((item, index) =>
                index === 0 ? (
                  <CollapsibleComponent
                    data1={item}
                    id={productCatId}
                    isCollapsOpen={false}
                    key={index}
                  />
                ) : (
                  <CollapsibleComponent
                    data1={item}
                    id={productCatId}
                    isCollapsOpen={false}
                    key={index}
                  />
                )
              )
            )}
          </div>
        </Wrapper>
      </div>
    </AppLayout>
  );
}
