import React, { useEffect, useState } from "react";
import "./aboutHistory.scss";
import parse from "html-react-parser";
import { motion } from "framer-motion";

// icons
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";

// swiper
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation } from "swiper";

// api
import { getAboutHistoryData } from "../../../../../apis/aboutTocoma.apis";

const AboutHistory = () => {
  const [activeEvent, setActiveEvent] = useState(0);
  const [historyData, setHistoryData] = useState([]);

  useEffect(() => {
    getAboutHistoryData()
      .then((res) => {
        setHistoryData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const handlEventClick = (e) => {
    setActiveEvent(e);
  };

  // swiper
  const [swiper, setSwiper] = useState(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const handleShiftLeft = () => {
    if (swiper) {
      swiper.slidePrev();
    }
  };
  const handleShiftRight = () => {
    if (swiper) {
      swiper.slideNext();
    }
  };
  useEffect(() => {
    if (swiper) {
      swiper.on("slideChange", () => {
        if (swiper.isBeginning) {
          setIsBeginning(true);
          setIsEnd(false);
        } else if (swiper.isEnd) {
          setIsBeginning(false);
          setIsEnd(true);
        } else {
          setIsBeginning(false);
          setIsEnd(false);
        }
      });
    }
  }, [swiper]);

  return (
    <div className="historyContent__container">
      <div className="historyContent__HeaderAndContent">
        <div className="historyHeader">
          <h3 className="historyHeader__text">{historyData?.TITLE}</h3>

          <span>
            {historyData?.DESCRIPTION && parse(historyData?.DESCRIPTION)}
          </span>
        </div>

        <motion.div
          className="aboutPage__historyContent"
          key={activeEvent}
          initial={{ x: 20, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -20, opacity: 0 }}
          transition={{ duration: 0.7 }}
        >
          {historyData?.historyList?.length > 0 && (
            <>
              <div className="historyContent__left">
                <img
                  src={historyData.historyList[activeEvent]?.IMAGE_LINK}
                  alt=""
                />
              </div>

              <div className="historyContent__right">
                <span className="historyDate">
                  {historyData.historyList[activeEvent]?.YEAR}-
                  {historyData.historyList[activeEvent]?.MONTH}
                </span>

                <h2 className="categoryName">
                  {historyData.historyList[activeEvent]?.TITLE}
                </h2>

                <span className="historyParagraph">
                  {/* {parse(
                    historyData[activeEvent].DESCRIPTION.length > 400
                      ? historyData[activeEvent].DESCRIPTION.slice(0, 400) +
                          "..."
                      : historyData[activeEvent].DESCRIPTION
                  )} */}

                  {historyData.historyList[activeEvent].DESCRIPTION &&
                    parse(historyData.historyList[activeEvent].DESCRIPTION)}
                </span>
              </div>
            </>
          )}
        </motion.div>
      </div>

      <div className="historyEvent">
        <MdOutlineArrowBackIos onClick={handleShiftLeft} />
        <Swiper
          className="swiper__barContainer"
          onSwiper={setSwiper}
          spaceBetween={0}
          slidesPerView={5}
          // modules={[Navigation]}
          // navigation
        >
          {historyData.historyList &&
            historyData?.historyList?.map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <div
                    className={
                      activeEvent === index
                        ? "historyEvent__box active"
                        : "historyEvent__box"
                    }
                  >
                    <span className="historyEvent__month">
                      {item?.MONTH.slice(0, 3)}
                    </span>

                    <span className="historyEvent__year">{item?.YEAR}</span>

                    <div className="barLine"></div>
                    <div
                      className={
                        activeEvent === index ? "barBox active" : "barBox"
                      }
                      onClick={() => handlEventClick(index)}
                    ></div>
                    <div className="barLine"></div>
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
        <MdOutlineArrowForwardIos onClick={handleShiftRight} />
      </div>
    </div>
  );
};

export default AboutHistory;
