import axios from "axios";

import { ROOT_URL, WORK_WITH_US_URL } from "../utils/urls";

export const getLandingCareerData = () => {
  return axios({
    method: "GET",
    url: "https://www.tocoma.co/api/WorkWithUs/CareerBanner",
  });
};

export const getCareerPageData = () => {
  return axios({
    method: "GET",
    url: ROOT_URL + "/WorkWithUs/CareerPageBanner",
  });
};

export const getWorkWithUsData = () => {
  return axios({
    method: "GET",
    url: WORK_WITH_US_URL,
  });
};

export const getWorkWithUsDataByDeptId = (id) => {
  return axios({
    method: "GET",
    url: WORK_WITH_US_URL + "/GetPositionListByDepartmentId" + "/" + id,
  });
};

export const getPositionDetailsById = (id) => {
  return axios({
    method: "GET",
    url: WORK_WITH_US_URL + "/GetJobDetails" + "/" + id,
  });
};

export const applyForJob = (data) => {
  return axios({
    method: "POST",
    url: "https://www.tocoma.co/api/WorkWithUs/ApplyJob",
    data,
  });
};
