import React, { useEffect, useState } from "react";
import "../openPositionSingleList/openPositionList.scss";
import "./workWithUsAllList.scss";
import { Link } from "react-router-dom";

// icons
import { MdOutlineKeyboardArrowRight } from "react-icons/md";

// api import
import { getWorkWithUsData } from "../../../apis/workWithUs.apis";

// components
import AppLayout from "../../../components/layouts/appLayout/AppLayout";
import Wrapper from "../../../components/common/wrapper/Wrapper";
import SubHero from "../../../components-ui/heroSections/subHero/SubHero";
import CollapsiblePositions from "./components/collapsible/CollapsiblePositions";

const WorkWithUsAllList = () => {
  const [workWithUsDeptData, setWorkWithUsDeptData] = useState([]);
  useEffect(() => {
    getWorkWithUsData()
      .then((res) => {
        setWorkWithUsDeptData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <AppLayout>
      <div className="globalPageLayout">
        <SubHero title={"Career"} />

        <Wrapper>
          <div className="pageSection__container allOpenPositionsPage">
            <div className="common__headerLink__component">
              <Link to="/" className="common__headerLinkContainer">
                <span className="linkText">Home</span>
                <i className="fa-solid fa-angle-right"></i>
              </Link>

              <Link to="/career" className="common__headerLinkContainer ">
                <span className="linkText">Career</span>
                <i className="fa-solid fa-angle-right"></i>
              </Link>

              <div className="common__headerLinkContainer common__headerLinkContainer__active">
                <span className="linkText">Position List</span>
              </div>
            </div>

            <div className="openPositions__container">
              {workWithUsDeptData.map((dept, index) => {
                return (
                  <CollapsiblePositions
                    key={index}
                    data={dept}
                    // isCollapsOpen={index === 0 ? true : false}
                    isCollapsOpen={false}
                  />
                );
              })}
            </div>
          </div>
        </Wrapper>
      </div>
    </AppLayout>
  );
};

export default WorkWithUsAllList;
