import React from "react";
import "./newsEventsDetail.scss";

import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";

// componets import
// import Carousal from "../../../components/commonPageComponent/carousel/Carousal";
import Carousal from "../../../components/commonPageComponent/carousel1/Carousal";
import Wrapper from "../../../components/common/wrapper/Wrapper";

import { getNewsAndEventsDetailData } from "../../../apis/newsAndEvents.apis";
import { useState } from "react";
import { useEffect } from "react";

const NewsEventsDetail = () => {
  const { newsId } = useParams();

  // formate date
  const formateDate = (date) => {
    const d = new Date(date);
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "short" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return `${da} ${mo} ${ye}`;
  };

  const [newsAndEventsDetailData, setNewsAndEventsDetailData] = useState({});
  useEffect(() => {
    getNewsAndEventsDetailData({ id: newsId })
      .then((res) => {
        console.log(res.data);
        setNewsAndEventsDetailData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [newsId]);
  // console.log(newsAndEventsDetailData);

  return (
    <div className="newsAndEventsDetails">
      <div className="topMask" />
      <Carousal />

      <Wrapper>
        <div className="common__headerLink__component">
          <Link to="/" className="common__headerLinkContainer">
            <span className="linkText">Home</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <Link to="/news&events" className="common__headerLinkContainer">
            <span className="linkText">All News And Events</span>
            <i className="fa-solid fa-angle-right"></i>
          </Link>

          <div className="common__headerLinkContainer common__headerLinkContainer__active">
            <span className="linkText">Details</span>
            <i className="fa-solid fa-angle-right"></i>
          </div>
        </div>

        <div className="newsAndEventsDetails__container">
          <div className="newsAndEventsDetails__headerAndImg">
            <div className="newsAndEventsDetails__headerLeft">
              <p className="newsAndEventsDetails__header__date">
                {newsAndEventsDetailData?.POSTING_DATE &&
                  formateDate(newsAndEventsDetailData?.POSTING_DATE)}
              </p>

              <div className="newsAndEventsDetails__header__title">
                <h1>{newsAndEventsDetailData?.NEWS_EVENTS_TITLE}</h1>
              </div>

              <div className="newsAndEventsDetails__ReporterContent">
                <div className="newsAndEventsDetails__ReporterImg">
                  <img
                    src="https://d2qp0siotla746.cloudfront.net/img/use-cases/profile-picture/template_0.jpg"
                    alt=""
                  />
                </div>

                <div className="newsAndEventsDetails__ReporterInfo">
                  <p className="newsAndEventsDetails__ReporterInfo__name">
                    Reporter Name
                  </p>

                  <div className="newsAndEventsDetails__ReporterSocial">
                    <div className="ReporterSocial__icon">
                      <i className="fa-brands fa-facebook-f"></i>
                    </div>
                    <div className="ReporterSocial__icon">
                      <i className="fa-brands fa-twitter"></i>
                    </div>
                    <div className="ReporterSocial__icon">
                      <i className="fa-brands fa-instagram"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <img
              src={newsAndEventsDetailData?.IMAGE_STRING}
              alt=""
              width="100%"
              height="100%"
            />
          </div>

          <p>
            {newsAndEventsDetailData?.DESCRIPTION &&
              parse(newsAndEventsDetailData?.DESCRIPTION)}
          </p>

          {newsAndEventsDetailData?.IMAGE_LINK && (
            <div className="newsAndEventsDetails__image2">
              <img src={newsAndEventsDetailData?.IMAGE_LINK} alt="" />
            </div>
          )}

          {newsAndEventsDetailData?.DESCRIPTION2 && (
            <div className="newsAndEventsDetails__descPart2">
              <p>{parse(newsAndEventsDetailData?.DESCRIPTION2)}</p>
            </div>
          )}
        </div>
      </Wrapper>
    </div>
  );
};

export default NewsEventsDetail;
