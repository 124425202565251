import React, { useEffect, useState } from "react";
import "./landingCareer.scss";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

// api services
import { getLandingCareerData } from "../../../apis/workWithUs.apis";

// components
import Wrapper from "../../../components/common/wrapper/Wrapper";
import PrimaryBtn from "../../../components/buttons/primaryBtn/PrimaryBtn";

const LandingCareer = () => {
  const [careerData, setCareerData] = useState({});

  useEffect(() => {
    getLandingCareerData()
      .then((res) => {
        setCareerData(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  return (
    <div className="landingCareer">
      <Wrapper>
        <div className="landingCareer__conatiner">
          <div className="homeSection__headerDesc">
            <span className="homeSection__headerText">{careerData?.TITLE}</span>

            {careerData?.SHORT_DESCRIPTION && (
              <span className="homeSection__descText">
                {parse(careerData?.SHORT_DESCRIPTION)}
              </span>
            )}
          </div>
          <div className="landingCareer__cover">
            <div className="landingCareer__conatiner__left">
              <img src={careerData?.BANNER_IMAGE} alt="" />
            </div>

            {/* <div className="landingCareer__conatiner__right">
              <div className="landingCareerText">
                <span>
                  {careerData?.BANNER_DESCRIPTION &&
                    parse(careerData?.BANNER_DESCRIPTION)}
                </span>
              </div>
            </div> */}
          </div>

          <div className="landingCareer__btn">
            <PrimaryBtn
              link={"/career"}
              name={careerData?.ARROW_TEXT}
              color={"black"}
              size={"large"}
            />
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default LandingCareer;
