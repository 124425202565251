import React from "react";
import "./borderBtn.scss";
import { Link } from "react-router-dom";

const BorderBtn = ({ link, name, size, color }) => {
  return (
    <Link
      to={link}
      className={`borderBtn link ${color === "white" ? "white" : "black"} ${
        size === "small" ? "small" : size === "medium" ? "medium" : "large"
      }`}
    >
      <span
        className={`borderBtnText 
        ${color === "white" ? "white" : "black"} ${
          size === "small" ? "small" : size === "medium" ? "medium" : "large"
        }`}
      >
        {name}
      </span>
    </Link>
  );
};

export default BorderBtn;
