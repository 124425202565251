import React, { useState } from "react";
import "./aboutCoreValues.scss";
import parse from "html-react-parser";

// icons
import {
  MdOutlineKeyboardArrowUp,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";

// collapsible component
import Collapsible from "react-collapsible";

const AboutCoreValues = ({ isCollapsOpen, data }) => {
  const [isOpen, setIsOpen] = useState(isCollapsOpen || false);

  const handleCollapsible = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="aboutCoreValues">
      <Collapsible
        open={isOpen}
        trigger={
          <div
            className="aboutPage__collapsible__trigger"
            onClick={handleCollapsible}
          >
            <h2>Core Values</h2>

            <>
              {isOpen ? (
                <MdOutlineKeyboardArrowUp className="amv__collapsible__icon" />
              ) : (
                <MdOutlineKeyboardArrowDown className="amv__collapsible__icon" />
              )}
            </>
          </div>
        }
        // triggerWhenOpen="Our Mission"
        // triggerClassName="collapsible__trigger"
        // triggerOpenedClassName="collapsible__trigger--open"
        // contentOuterClassName="collapsible__contentOuter"
        // contentInnerClassName="collapsible__contentInner"
      >
        <div className="acv__innerContainer">
          {data?.DESCRIPTION && (
            <span className="acv__text">{parse(data?.DESCRIPTION)}</span>
          )}

          <div className="acv__itemList">
            {data &&
              data?.typeList?.map((item, index) => (
                <div key={index} className="acv__item">
                  <div className="acv__itemCount">{index + 1}</div>

                  <div className="acv__itemInfo">
                    <h5>{item.TITLE}</h5>
                    <span>{item.DESCRIPTION}</span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Collapsible>
    </div>
  );
};

export default AboutCoreValues;
