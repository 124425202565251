import React, { useEffect, useRef, useState } from "react";
import "./workWithUsFormPage.scss";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

// icons
import { MdKeyboardArrowDown } from "react-icons/md";
import { RiPencilFill } from "react-icons/ri";
import { RxCross2 } from "react-icons/rx";

// api
import { getPositionDetailsById } from "../../../apis/workWithUs.apis";
import { applyForJob } from "../../../apis/workWithUs.apis";

// components import
import Wrapper from "../../../components/common/wrapper/Wrapper";
import SubHero from "../../../components-ui/heroSections/subHero/SubHero";
import AppLayout from "../../../components/layouts/appLayout/AppLayout";
import BorderBtn from "../../../components/buttons/borderBtn/BorderBtn";

const WorkWithUsFormPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { positionId } = useParams();

  // states
  const [selectedImage, setSelectedImage] = useState(null);
  const [backendImage, setBackendImage] = useState(null);
  const [selectedResume, setSelectedResume] = useState(null);
  const fileInputRef = useRef(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedImage(imageUrl);
      setBackendImage(file);
    }
  };

  const handleResumeChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedResume(file);
    }
  };
  const handleRemoveCv = () => {
    setSelectedResume(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset the file input value
    }
  };

  // api data
  const [positionDetails, setPositionDetails] = useState({});
  useEffect(() => {
    getPositionDetailsById(positionId)
      .then((res) => {
        setPositionDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [positionId]);

  // states
  const [jobInfoInputs, setJobInfoInputs] = useState({
    FIRST_NAME: "",
    LAST_NAME: "",
    EMAIL: "",
    ADDRESS: "",
    PHONE: "",
    MESSAGE: "",
  });
  const [experienceInputs, setExperienceInputs] = useState([
    {
      POSITION: "",
      DEPARTMENT: "",
      COMPANY_NAME: "",
      COMPANY_ADDRESS: "",
      WORK_FROM: "",
      WORK_TO: "",
      IS_CORRENTY_WORK: false,
    },
  ]);
  const handleNewExperience = () => {
    setExperienceInputs([
      ...experienceInputs,
      {
        POSITION: "",
        DEPARTMENT: "",
        COMPANY_NAME: "",
        COMPANY_ADDRESS: "",
        WORK_FROM: "",
        WORK_TO: "",
        IS_CORRENTY_WORK: false,
      },
    ]);
  };
  const [educationInputs, setEducationInputs] = useState([
    {
      INSTITUTION: "",
      MAJOR: "",
      DEGREE: "",
      LOCATION: "",
      DATE_FROM: "",
      DATE_TO: "",
      IS_CURRENTLY_ATTEND: false,
    },
  ]);
  const handleNewEducation = () => {
    setEducationInputs([
      ...educationInputs,
      {
        INSTITUTION: "",
        MAJOR: "",
        DEGREE: "",
        LOCATION: "",
        DATE_FROM: "",
        DATE_TO: "",
        IS_CURRENTLY_ATTEND: false,
      },
    ]);
  };

  const handleInputChange = (e, section, index = null) => {
    const { name, value, type, checked } = e.target;
    if (section === "jobInfo") {
      setJobInfoInputs((prev) => ({ ...prev, [name]: value }));
    } else if (section === "experience") {
      const newExperienceInputs = [...experienceInputs];
      newExperienceInputs[index][name] = type === "checkbox" ? checked : value;
      setExperienceInputs(newExperienceInputs);
    } else if (section === "education") {
      const newEducationInputs = [...educationInputs];
      newEducationInputs[index][name] = type === "checkbox" ? checked : value;
      setEducationInputs(newEducationInputs);
    }
  };

  const handleChangeCheckbox = (e, section, index) => {
    const { name, checked } = e.target;
    if (section === "experience") {
      const newExperienceInputs = [...experienceInputs];
      newExperienceInputs[index][name] = checked;
      setExperienceInputs(newExperienceInputs);
    } else if (section === "education") {
      const newEducationInputs = [...educationInputs];
      newEducationInputs[index][name] = checked;
      setEducationInputs(newEducationInputs);
    }
  };

  const [formErrors, setFormErrors] = useState(null);
  const formValidation = () => {
    if (
      jobInfoInputs.FIRST_NAME === "" ||
      jobInfoInputs.LAST_NAME === "" ||
      jobInfoInputs.EMAIL === "" ||
      jobInfoInputs.ADDRESS === "" ||
      jobInfoInputs.PHONE === "" ||
      jobInfoInputs.MESSAGE === ""
    ) {
      setFormErrors("Please fill all the required fields");
      return false;
    } else if (experienceInputs.length === 0) {
      setFormErrors("Please fill at least one experience field");
      return false;
    } else if (educationInputs.length === 0) {
      setFormErrors("Please fill at least one education field");
      return false;
    } else if (!selectedResume) {
      setFormErrors("Please upload your resume");
      return false;
    } else {
      setFormErrors(null);
      return true;
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!formValidation()) {
      return;
    }

    const formData = new FormData();
    formData.append("JOB_DESCRIPTION_ID", 1);
    formData.append("FIRST_NAME", jobInfoInputs.FIRST_NAME);
    formData.append("LAST_NAME", jobInfoInputs.LAST_NAME);
    formData.append("EMAIL", jobInfoInputs.EMAIL);
    formData.append("ADDRESS", jobInfoInputs.ADDRESS);
    formData.append("PHONE", jobInfoInputs.PHONE);
    formData.append("MESSAGE", jobInfoInputs.MESSAGE);

    experienceInputs.forEach((exp, index) => {
      formData.append(`experianceList[${index}][POSITION]`, exp.POSITION);
      formData.append(`experianceList[${index}][DEPARTMENT]`, exp.DEPARTMENT);
      formData.append(
        `experianceList[${index}][COMPANY_NAME]`,
        exp.COMPANY_NAME
      );
      formData.append(
        `experianceList[${index}][COMPANY_ADDRESS]`,
        exp.COMPANY_ADDRESS
      );
      formData.append(`experianceList[${index}][WORK_FROM]`, exp.WORK_FROM);
      formData.append(`experianceList[${index}][WORK_TO]`, exp.WORK_TO);
      formData.append(
        `experianceList[${index}][IS_CORRENTY_WORK]`,
        exp.IS_CORRENTY_WORK
      );
    });

    educationInputs.forEach((edu, index) => {
      formData.append(`educationList[${index}][INSTITUTION]`, edu.INSTITUTION);
      formData.append(`educationList[${index}][MAJOR]`, edu.MAJOR);
      formData.append(`educationList[${index}][DEGREE]`, edu.DEGREE);
      formData.append(`educationList[${index}][LOCATION]`, edu.LOCATION);
      formData.append(`educationList[${index}][DATE_FROM]`, edu.DATE_FROM);
      formData.append(`educationList[${index}][DATE_TO]`, edu.DATE_TO);
      formData.append(
        `educationList[${index}][IS_CURRENTLY_ATTEND]`,
        edu.IS_CURRENTLY_ATTEND
      );
    });

    formData.append("File_CV", selectedResume);
    formData.append("File_PICTURE", backendImage);

    applyForJob(formData)
      .then((res) => {
        console.log(res);

        // clear form
        setJobInfoInputs({
          FIRST_NAME: "",
          LAST_NAME: "",
          EMAIL: "",
          ADDRESS: "",
          PHONE: "",
          MESSAGE: "",
        });
        setExperienceInputs([
          {
            POSITION: "",
            DEPARTMENT: "",
            COMPANY_NAME: "",
            COMPANY_ADDRESS: "",
            WORK_FROM: "",
            WORK_TO: "",
            IS_CORRENTY_WORK: false,
          },
        ]);

        setEducationInputs([
          {
            INSTITUTION: "",
            MAJOR: "",
            DEGREE: "",
            LOCATION: "",
            DATE_FROM: "",
            DATE_TO: "",
            IS_CURRENTLY_ATTEND: false,
          },
        ]);

        setSelectedImage(null);
        setBackendImage(null);
        setSelectedResume(null);

        setTimeout(() => {
          navigate(`/confirmation`);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });

    // print form data to console as json object
    // const object = {};
    // formData.forEach((value, key) => {
    //   object[key] = value;
    // });
    // console.log(object);
  };

  return (
    <AppLayout>
      <div className="workWithUsFormPage">
        <SubHero title={"Career"} />

        <Wrapper>
          <div className="common__headerLink__component">
            <Link to="/" className="common__headerLinkContainer">
              <span className="linkText">Home</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <Link to="/career" className="common__headerLinkContainer ">
              <span className="linkText">Career</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <Link
              to={`/career/career-list`}
              className="common__headerLinkContainer "
            >
              <span className="linkText">Position List</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <Link
              to={`/career/${positionId}`}
              className="common__headerLinkContainer "
            >
              <span className="linkText">Positon Details</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <div className="common__headerLinkContainer common__headerLinkContainer__active">
              <span className="linkText">Apply</span>
            </div>
          </div>

          <form
            action=""
            onSubmit={handleFormSubmit}
            className="wwufp__container"
          >
            <div className="opc__top">
              <div className="opc__positionInfos">
                <span className="opc__title">{positionDetails?.POSITION}</span>

                <div className="opc__positionInfoList">
                  <span>Experiance : {positionDetails?.EXPERIANCE}</span>
                  <span>Contract Type : {positionDetails?.CONTRACT_TYPE}</span>
                  <span>Job Type : {positionDetails?.EMPLOYMENT_STATUS}</span>
                  <span>Location : {positionDetails?.JOB_LOCATION}</span>
                </div>
              </div>
            </div>

            <div className="wwufp__formContainer">
              <div className="wwufp__inputSection">
                <div className="inputSection__topPersonal">
                  <div className="inputSection__topText">
                    <h3>Personal Information</h3>
                    <span>Fields marked with * are required.</span>
                  </div>

                  <div className="inputImage">
                    <div className="imageContainer">
                      {selectedImage && (
                        <img
                          src={
                            selectedImage
                              ? selectedImage
                              : positionDetails?.IMAGE
                          }
                          alt=""
                        />
                      )}
                    </div>

                    <input
                      type="file"
                      id="uploadInput"
                      onChange={handleImageChange}
                    />
                    <label for="uploadInput" className="inputImage__icon">
                      <RiPencilFill />
                    </label>
                  </div>
                </div>

                <div className="inputSection__container">
                  <input
                    type="text"
                    className="wwufp__formInput"
                    placeholder="First Name *"
                    name="FIRST_NAME"
                    value={jobInfoInputs.FIRST_NAME}
                    onChange={(e) => handleInputChange(e, "jobInfo")}
                  />

                  <input
                    type="text"
                    className="wwufp__formInput"
                    placeholder="Last Name *"
                    name="LAST_NAME"
                    value={jobInfoInputs.LAST_NAME}
                    onChange={(e) => handleInputChange(e, "jobInfo")}
                  />

                  <input
                    type="email"
                    className="wwufp__formInput"
                    placeholder="Email *"
                    name="EMAIL"
                    value={jobInfoInputs.EMAIL}
                    onChange={(e) => handleInputChange(e, "jobInfo")}
                  />

                  <input
                    type="text"
                    className="wwufp__formInput"
                    placeholder="Address *"
                    name="ADDRESS"
                    value={jobInfoInputs.ADDRESS}
                    onChange={(e) => handleInputChange(e, "jobInfo")}
                  />

                  <input
                    type="number"
                    className="wwufp__formInput"
                    placeholder="Phone *"
                    name="PHONE"
                    value={jobInfoInputs.PHONE}
                    onChange={(e) => handleInputChange(e, "jobInfo")}
                  />
                </div>
              </div>

              <div className="wwufp__inputSection">
                <div className="inputSection__top">
                  <div className="inputSection__topText">
                    <h3>Experience</h3>
                    <span>Fields marked with * are required.</span>
                  </div>

                  <div className="inputSection__topRight">
                    <div onClick={handleNewExperience} className="addOptionBtn">
                      Add +
                    </div>
                    <MdKeyboardArrowDown />
                  </div>
                </div>

                {experienceInputs.map((experience, index) => (
                  <div className="multiInput__container" key={index}>
                    <div className="multiInputSection__container">
                      <input
                        type="text"
                        className="wwufp__formInput"
                        placeholder="Position *"
                        name="POSITION"
                        value={experience.POSITION}
                        onChange={(e) =>
                          handleInputChange(e, "experience", index)
                        }
                      />

                      <input
                        type="text"
                        className="wwufp__formInput"
                        placeholder="Department *"
                        name="DEPARTMENT"
                        value={experience.DEPARTMENT}
                        onChange={(e) =>
                          handleInputChange(e, "experience", index)
                        }
                      />

                      <input
                        type="text"
                        className="wwufp__formInput"
                        placeholder="Company Name *"
                        name="COMPANY_NAME"
                        value={experience.COMPANY_NAME}
                        onChange={(e) =>
                          handleInputChange(e, "experience", index)
                        }
                      />

                      <input
                        type="text"
                        className="wwufp__formInput"
                        placeholder="Address *"
                        name="COMPANY_ADDRESS"
                        value={experience.COMPANY_ADDRESS}
                        onChange={(e) =>
                          handleInputChange(e, "experience", index)
                        }
                      />

                      <div className="wwu__date">
                        <label htmlFor="">From * </label>

                        <input
                          type="date"
                          className="wwu__dateInput"
                          placeholder="From *"
                          name="WORK_FROM"
                          value={experience.WORK_FROM}
                          onChange={(e) =>
                            handleInputChange(e, "experience", index)
                          }
                        />
                      </div>

                      <div className="wwu__date">
                        <label htmlFor="">To * </label>

                        <input
                          type="date"
                          className="wwu__dateInput"
                          placeholder="To *"
                          name="WORK_TO"
                          value={experience.WORK_TO}
                          onChange={(e) =>
                            handleInputChange(e, "experience", index)
                          }
                        />
                      </div>
                    </div>

                    <div className="inputSection__bottom">
                      <div className="inputSection__bottomLeft">
                        <div className="checkGroup">
                          <input
                            type="checkbox"
                            name="IS_CORRENTY_WORK"
                            checked={experience.IS_CORRENTY_WORK}
                            onChange={(e) =>
                              handleChangeCheckbox(e, "experience", index)
                            }
                          />
                          <span>Currently Working</span>
                        </div>
                      </div>

                      <div className="inputSection__bottomRight">
                        <div
                          onClick={() => {
                            const newExperienceInputs = experienceInputs.filter(
                              (exp, i) => i !== index
                            );
                            setExperienceInputs(newExperienceInputs);
                          }}
                          className="btn"
                        >
                          <BorderBtn
                            name={"Remove"}
                            size={"small"}
                            color={"white"}
                          />
                        </div>

                        <BorderBtn
                          name={"Save"}
                          size={"small"}
                          color={"black"}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="wwufp__inputSection">
                <div className="inputSection__top">
                  <div className="inputSection__topText">
                    <h3>Education</h3>
                    <span>Fields marked with * are required.</span>
                  </div>

                  <div className="inputSection__topRight">
                    <div onClick={handleNewEducation} className="addOptionBtn">
                      Add +
                    </div>
                    <MdKeyboardArrowDown />
                  </div>
                </div>

                {educationInputs.map((education, index) => (
                  <div className="multiInput__container" key={index}>
                    <div className="inputSection__container">
                      <input
                        type="text"
                        className="wwufp__formInput"
                        placeholder="Institution *"
                        name="INSTITUTION"
                        value={education.INSTITUTION}
                        onChange={(e) =>
                          handleInputChange(e, "education", index)
                        }
                      />

                      <input
                        type="text"
                        className="wwufp__formInput"
                        placeholder="Major *"
                        name="MAJOR"
                        value={education.MAJOR}
                        onChange={(e) =>
                          handleInputChange(e, "education", index)
                        }
                      />

                      <input
                        type="text"
                        className="wwufp__formInput"
                        placeholder="Degree *"
                        name="DEGREE"
                        value={education.DEGREE}
                        onChange={(e) =>
                          handleInputChange(e, "education", index)
                        }
                      />

                      <input
                        type="text"
                        className="wwufp__formInput"
                        placeholder="Location *"
                        name="LOCATION"
                        value={education.LOCATION}
                        onChange={(e) =>
                          handleInputChange(e, "education", index)
                        }
                      />

                      <div className="wwu__date">
                        <label htmlFor="">From * </label>

                        <input
                          type="date"
                          className="wwu__dateInput"
                          placeholder="From *"
                          name="DATE_FROM"
                          value={education.DATE_FROM}
                          onChange={(e) =>
                            handleInputChange(e, "education", index)
                          }
                        />
                      </div>

                      <div className="wwu__date">
                        <label htmlFor="">To * </label>

                        <input
                          type="date"
                          className="wwu__dateInput"
                          placeholder="To *"
                          name="DATE_TO"
                          value={education.DATE_TO}
                          onChange={(e) =>
                            handleInputChange(e, "education", index)
                          }
                        />
                      </div>
                    </div>

                    <div className="inputSection__bottom">
                      <div className="inputSection__bottomLeft">
                        <div className="checkGroup">
                          <input
                            type="checkbox"
                            name="IS_CURRENTLY_ATTEND"
                            onChange={(e) =>
                              handleChangeCheckbox(e, "education", index)
                            }
                          />
                          <span>Currently attend</span>
                        </div>
                      </div>

                      <div className="inputSection__bottomRight">
                        <div
                          onClick={() => {
                            const newEducationInputs = educationInputs.filter(
                              (edu, i) => i !== index
                            );
                            setEducationInputs(newEducationInputs);
                          }}
                          className="btn"
                        >
                          <BorderBtn
                            name={"Remove"}
                            size={"small"}
                            color={"white"}
                          />
                        </div>

                        <BorderBtn
                          name={"Save"}
                          size={"small"}
                          color={"black"}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="wwufp__inputSection">
                <div className="inputSection__top">
                  <div className="inputSection__topText">
                    <h3>Resume</h3>
                  </div>
                </div>

                <div className="inputSection__container1">
                  <input
                    type="File"
                    className="wwufp__formInput"
                    accept=".pdf,.doc,.docx,.txt"
                    id="resumeInput"
                    ref={fileInputRef}
                    hidden
                    onChange={handleResumeChange}
                  />

                  <div className="fileUpload">
                    <label for="resumeInput" className="cvUploadLabel">
                      <span>
                        {selectedResume
                          ? selectedResume.name
                          : "Upload your resume *"}
                      </span>
                    </label>

                    {selectedResume && <RxCross2 onClick={handleRemoveCv} />}
                  </div>
                </div>
              </div>

              <div className="wwufp__inputSection">
                <div className="inputSection__top">
                  <div className="inputSection__topText">
                    <h3>Message to Hiring Manager</h3>
                    <span>
                      Let the company know about your interest working there.
                    </span>
                  </div>
                </div>

                <div className="inputSection__container1">
                  <textarea
                    id=""
                    rows={8}
                    className="wwufp__textarea"
                    placeholder="Message"
                    name="MESSAGE"
                    value={jobInfoInputs.MESSAGE}
                    onChange={(e) => handleInputChange(e, "jobInfo")}
                  ></textarea>
                </div>
              </div>
            </div>

            <div className="wwufp__bottom">
              {formErrors && (
                <span className="wwu__formErrors">{formErrors}</span>
              )}

              <button type="submit" className="borderBtn black medium">
                <span className="borderBtnText medium">Submit</span>
              </button>
            </div>
          </form>
        </Wrapper>
      </div>
    </AppLayout>
  );
};

export default WorkWithUsFormPage;
