import React, { useEffect, useState } from "react";
import "./productPage.scss";
import { Link } from "react-router-dom";

// product icons
import BrandGrayIcon from "../../../assets/images/productPage/ByBrand__grayIcon.png";
import BrandIcon from "../../../assets/images/productPage/ByBrand__icon.png";
import BySegmentIcon from "../../../assets/images/productPage/BySegment__icon.png";
import BySegmentGrayIcon from "../../../assets/images/productPage/BySegment__grayIcon.png";

// api
import { getSubHeadersData } from "../../../apis/subHeader.apis";
import { getProductPageData } from "../../../apis/products.apis";
import { getProductCarouselData } from "../../../apis/hero.apis";

// components
import Wrapper from "../../../components/common/wrapper/Wrapper";
import SubHero from "../../../components-ui/heroSections/subHero/SubHero";
import AppLayout from "../../../components/layouts/appLayout/AppLayout";
import PrimaryBtn from "../../../components/buttons/primaryBtn/PrimaryBtn";

export default function ProductPage() {
  const [selectedProduct, setSelectedProduct] = useState(2);

  // states
  const [subHeaderData, setSubHeaderData] = useState([]);
  const [slideData, setSlideData] = useState([]);
  const [productsTypeData, setProductsTypeData] = useState([]);
  useEffect(() => {
    // sub header
    getSubHeadersData()
      .then((res) => {
        const data = res.data.filter((item) => item.PAGE_SHORT_NAME === "PR");
        setSubHeaderData(data);
      })
      .catch((err) => {
        console.log(err);
      });

    // slide data
    getProductCarouselData()
      .then((res) => {
        setSlideData(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });

    getProductPageData()
      .then((res) => {
        setProductsTypeData(res.data);
        // console.log("productsTypeData", res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Data Filter
  const [byBrandData, setByBrandData] = useState([]);
  const [byApplicationData, setByApplicationData] = useState([]);
  const [bySegmentData, setBySegmentData] = useState([]);
  useEffect(() => {
    const bySegment = productsTypeData.filter((item) => item.TYPE === "C");
    setBySegmentData(bySegment);

    const byBrand = productsTypeData.filter((item) => item.TYPE === "B");
    setByBrandData(byBrand);

    const byApplication = productsTypeData.filter((item) => item.TYPE === "A");
    setByApplicationData(byApplication);
  }, [productsTypeData]);

  return (
    <AppLayout>
      <div className="ProductPageContainer">
        <SubHero
          title={subHeaderData && subHeaderData[0]?.TITLE}
          desc={subHeaderData && subHeaderData[0]?.DESCRIPTION}
        />

        <Wrapper>
          <div className="common__headerLink__component">
            <Link to="/" className="common__headerLinkContainer">
              <span className="linkText">Home</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <div className="common__headerLinkContainer common__headerLinkContainer__active">
              <span className="linkText">
                {selectedProduct === 1
                  ? "By Brand"
                  : selectedProduct === 2
                  ? "By Segment"
                  : // : selectedProduct === 3
                    // ? "By Application"
                    null}
              </span>
            </div>
          </div>

          <div className="containerItems">
            <div className="content">
              <div className="contentBtns">
                <div
                  className={
                    selectedProduct === 2
                      ? "contentBtnBox active"
                      : "contentBtnBox"
                  }
                  onClick={() => setSelectedProduct(2)}
                >
                  <div className="c">
                    <img
                      src={
                        selectedProduct === 2
                          ? BySegmentIcon
                          : BySegmentGrayIcon
                      }
                      alt=""
                    />
                    <h2>By Categories</h2>
                  </div>

                  {selectedProduct === 2 ? (
                    <i className="fas fa-chevron-down"></i>
                  ) : (
                    <i className="fas fa-chevron-up"></i>
                  )}
                </div>

                <div
                  className={
                    selectedProduct === 1
                      ? "contentBtnBox active"
                      : "contentBtnBox"
                  }
                  onClick={() => setSelectedProduct(1)}
                >
                  <div className="c">
                    <img
                      src={selectedProduct === 1 ? BrandIcon : BrandGrayIcon}
                      alt=""
                    />
                    <h2>By Brand</h2>
                  </div>
                  {selectedProduct === 1 ? (
                    <i className="fas fa-chevron-down"></i>
                  ) : (
                    <i className="fas fa-chevron-up"></i>
                  )}
                </div>

                {/* <div
                  className={
                    selectedProduct === 3
                      ? "contentBtnBox active"
                      : "contentBtnBox"
                  }
                  onClick={() => setSelectedProduct(3)}
                >
                  <img
                    src={
                      selectedProduct === 3
                        ? ByApplicationIcon
                        : ByApplicationGrayIcon
                    }
                    alt=""
                  />
                  <h2>By Application</h2>
                  {selectedProduct === 3 ? (
                    <i className="fas fa-chevron-down"></i>
                  ) : (
                    <i className="fas fa-chevron-up"></i>
                  )}
                </div> */}
              </div>

              {selectedProduct === 1 && (
                <div className="contentBottom">
                  {byBrandData.map((item, index) => {
                    return (
                      <Link
                        to={`/products/list/1/${item.CATEGORY_ID}`}
                        key={index}
                      >
                        <div className="BrandBox">
                          <img src={item.IMAGE_STRING} alt="" />

                          <div className="contentBottomBtn">
                            <div className="contentBottomBtn1">
                              <PrimaryBtn size={"small"} color={"gray"} />
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              )}

              {selectedProduct === 2 && (
                <div className="contentBottom">
                  {bySegmentData.map((item, index) => {
                    return (
                      <Link
                        to={`/products/list/2/${item.CATEGORY_ID}`}
                        className="SegmentBottomBox link"
                        key={index}
                      >
                        <div className="SegmentBox__img">
                          <img src={item.IMAGE_STRING} alt="" />
                        </div>

                        <div className="SegmentBox__name">
                          <span>{item.CATEGORY_NAME}</span>
                        </div>
                      </Link>
                    );
                  })}
                </div>
              )}

              {selectedProduct === 3 && (
                <div className="contentBottom">
                  {byApplicationData.map((item, index) => {
                    return (
                      <Link
                        to={`/products/list/3/${item.CATEGORY_ID}`}
                        className="SegmentBottomBox"
                        key={index}
                      >
                        <img src={item.IMAGE_STRING} alt="" />
                        <span className="SegmentBottomBoxSpan">
                          {item.CATEGORY_NAME}
                        </span>
                      </Link>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </Wrapper>
      </div>
    </AppLayout>
  );
}
