import axios from "axios";

import { ROOT_URL } from "../utils/urls";

export const getContactInfo = () => {
  return axios({
    method: "GET",
    url: ROOT_URL + "/Contact/GetOfficeAddress",
  });
};

export const getContactDropdownItems = () => {
  return axios({
    method: "GET",
    url: ROOT_URL + "/Contact/GetDropdownItem",
  });
};

export const submitContactForm = (data) => {
  return axios({
    method: "POST",
    url: "https://www.tocoma.co/api/Contact/SubmitApplication",
    data: data,
  });
};
