import React, { useEffect } from "react";
import "./styles/global.scss";

import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { NavProvider } from "./context/NavContext";

// landing page import
import LandingPage from "./Screens/landingPage2/LandingPage";

// about pages import
import AboutPage from "./Screens/aboutPage/aboutPage/AboutPage";
import AboutDetailPage from "./Screens/aboutPage/aboutDetailPage/AboutDetailPage";

// client pages import
import ClientListPage from "./Screens/clientListPage/ClientListPage";

// partners Pages import
import PartnersPage from "./Screens/partners/partnersPage/PartnersPage";
import PartnerDetailPage from "./Screens/partners/partnerDetailPage/PartnerDetailPage";

// Refecence Pages import
// import ReferenceProject from "./Screens/reference/referenceProject/ReferenceProject";
import ReferanceProjectList from "./Screens/reference/referanceProjectList/ReferenceProjectList";
import ReferenceProjectDetails from "./Screens/reference/referenceProjectDetails/ReferenceProjectDetails";

// work with us import
import WorkWithUs from "./Screens/workWithUs/workWithUs/WorkWithUs";
import WorkWithUsAllList from "./Screens/workWithUs/workWithUsAllList/WorkWithUsAllList";
// import OpenPositionList from "./Screens/workWithUs/openPositionSingleList/OpenPositionList";
import OpenPositionDetails from "./Screens/workWithUs/openPositionDetails/OpenPositionDetails";
import WorkWithUsFormPage from "./Screens/workWithUs/workWitgUsFormPage/WorkWithUsFormPage";

// news and events import
import NewsAndEventsPage from "./Screens/newsAndEvents/newsAndEventsPage/NewsAndEventsPage";
import NewsEventsDetail from "./Screens/newsAndEvents/newsAndEventsDetails/NewsEventsDetail";

// download page import
import DownloadPage from "./Screens/download/DownloadPage";

// contact page import
import ContactUs from "./Screens/contactUs/ContactUs";

// product Pages import
import ProductPage from "./Screens/productPages/productPage/ProductPage";
import ProductList1 from "./Screens/productPages/productListPage/ProductList";
import ProductDetail1 from "./Screens/productPages/productDetailsPage/ProductDetailsPage";

// search page import
import SearchPage from "./Screens/searchPage/SearchPage";

// Target Market Pages import
import TargetMarket from "./Screens/targetMarket/TargetMarket";

// confirmation page import
import ConfirmationPage from "./Screens/confirmationPage/ConfirmationPage";

// error page import
import ErrorPage from "../src/Screens/errorPage/ErrorPage";

export default function App() {
  const { pathname } = useLocation();
  // const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className="app">
      <NavProvider>
        <Routes>
          <Route path="/" element={<LandingPage />} />

          {/* target market */}
          <Route path="/target-market" element={<TargetMarket />} />

          {/* about pages */}
          <Route path="/about" element={<Outlet />}>
            <Route index element={<AboutPage />} />
            <Route path=":aboutId" element={<AboutDetailPage />} />
          </Route>

          {/* client pages */}
          <Route path="/clients" element={<ClientListPage />} />

          {/* products */}
          <Route path="/products" element={<Outlet />}>
            <Route index element={<ProductPage />} />
            <Route
              path="list/:stateId/:productCatId"
              element={<ProductList1 />}
            />
            <Route
              path=":productCatId/:productId"
              element={<ProductDetail1 />}
            />
          </Route>

          {/* reference */}
          <Route path="/reference" element={<Outlet />}>
            {/* <Route path="" element={<ReferenceProject />} />
            <Route path=":refTypeId" element={<ReferanceProjectList />} /> */}
            <Route path="" element={<ReferanceProjectList />} />
            <Route
              path=":refTypeId/:refProjectId"
              element={<ReferenceProjectDetails />}
            />
          </Route>

          {/* partners */}
          <Route path="/partners" element={<Outlet />}>
            <Route path="" element={<PartnersPage />} />
            <Route path=":partnerId" element={<PartnerDetailPage />} />
          </Route>

          {/* news and events */}
          <Route path="/news&events" element={<Outlet />}>
            <Route path="" element={<NewsAndEventsPage />} />
            <Route path=":newsId" element={<NewsEventsDetail />} />
          </Route>

          {/* Work Wit Us */}
          <Route path="/career" element={<Outlet />}>
            <Route path="" element={<WorkWithUs />} />
            <Route path="career-list" element={<WorkWithUsAllList />} />
            <Route path=":positionId" element={<OpenPositionDetails />} />
            <Route path="apply/:positionId" element={<WorkWithUsFormPage />} />
          </Route>

          {/* download */}
          <Route path="/download" element={<DownloadPage />} />

          {/* contact us */}
          <Route path="/contact" element={<ContactUs />} />

          {/* search */}
          <Route path="/search" element={<SearchPage />} />

          {/* confirmation */}
          <Route path="/confirmation" element={<ConfirmationPage />} />

          {/* error page */}
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </NavProvider>
    </div>
  );
}
