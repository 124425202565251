import axios from "axios";

import { ROOT_URL } from "../utils/urls";

export const getSubHeadersData = () => {
  return axios({
    method: "GET",
    url: ROOT_URL + "/AboutTocoma/GetPageHead",
  });
};
