import React, { useEffect, useState } from "react";
import "./partnerDetailPage.scss";
import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";

// img
import iconPdf from "./assets/pdf-icon.png";

// api
import { getOurPartnerDetailsData } from "../../../apis/ourPartners.apis";

// components
import Wrapper from "../../../components/common/wrapper/Wrapper";
import SubHero from "../../../components-ui/heroSections/subHero/SubHero";
import AppLayout from "../../../components/layouts/appLayout/AppLayout";

export default function PartnerDetailPage() {
  const { partnerId } = useParams();

  // api call
  const [partnerDetailData, setPartnerDetailData] = useState([]);
  useEffect(() => {
    getOurPartnerDetailsData({ id: partnerId })
      .then((res) => {
        setPartnerDetailData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [partnerId]);
  // console.log("partnerDetailData1", partnerDetailData);

  return (
    <AppLayout>
      <div className="pd">
        <SubHero title={"Partner Details"} />

        <Wrapper>
          <div className="common__headerLink__component">
            <Link to="/" className="common__headerLinkContainer">
              <span className="linkText">Home</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <Link to="/partners" className="common__headerLinkContainer">
              <span className="linkText">Partners</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <Link
              to=""
              className="common__headerLinkContainer common__headerLinkContainer__active"
            >
              <span className="linkText">Partner Details</span>
            </Link>
          </div>

          <div className="partnerDetailPage">
            <div className="partnerDetailPage__container">
              <div className="partnerLogo__container">
                <img src={partnerDetailData?.LOGO} alt="" />
              </div>

              <div className="partnerDetailPage__text">
                {partnerDetailData.DETAILS_DESCRIPTION &&
                  parse(partnerDetailData?.DETAILS_DESCRIPTION)}
              </div>

              <div className="pd__companyProfile">
                <div className="companyProfile__left">
                  <h4>Company Profile</h4>

                  <div className="companyProfile__list">
                    <div className="companyProfile__listItem">
                      <span className="companyProfile__Title">
                        Company Name
                      </span>

                      <ul>
                        <li>{partnerDetailData?.COMPANY_NAME}</li>
                      </ul>
                    </div>

                    <div className="companyProfile__listItem">
                      <span className="companyProfile__Title">Brand Name</span>

                      <ul>
                        <li>{partnerDetailData?.BRAND_NAME}</li>
                      </ul>
                    </div>

                    <div className="companyProfile__listItem">
                      <span className="companyProfile__Title">
                        Established in
                      </span>

                      <ul>
                        <li>{partnerDetailData?.ESTABLISHMENT_YEAR}</li>
                      </ul>
                    </div>

                    <div className="companyProfile__listItem">
                      <span className="companyProfile__Title">Website</span>

                      <ul>
                        {partnerDetailData?.WEBSITE ? (
                          <li>
                            <a
                              href={`http://${partnerDetailData?.WEBSITE}`}
                              target="_blank"
                              rel="noreferrer"
                              className="companyProfile__desc"
                            >
                              {partnerDetailData?.WEBSITE}
                            </a>
                          </li>
                        ) : (
                          <span className="companyProfile__desc">N/A</span>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="companyProfile__right">
                  {partnerDetailData?.BRAND_PROFILE_URL ? (
                    <a
                      href={partnerDetailData?.BRAND_PROFILE_URL}
                      target="_blank"
                      rel="noreferrer"
                      className="companyProfile__link"
                    >
                      <img src={iconPdf} alt="" />
                    </a>
                  ) : (
                    <div className="companyProfile__link blur">
                      <img src={iconPdf} alt="" />
                    </div>
                  )}
                </div>
              </div>

              <div className="partnerDetailPage__businessCart__container">
                <h3 className="header__text">Product Segment</h3>

                <div className="businessCards">
                  {partnerDetailData?.partnersBusinessList?.map(
                    (item, index) => {
                      return (
                        <div className="businessCard" key={index}>
                          <div className="businessCard__image">
                            <img src={item.IMAGE_LINK} alt="" />
                          </div>

                          <div className="businessCard__text">
                            <span>{item.IMAGE_TITLE}</span>
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>

              {partnerDetailData?.VIDEO_LINK && (
                <div className="pd__videoContainer">
                  <iframe
                    width="100%"
                    height="100%"
                    src={partnerDetailData?.VIDEO_LINK}
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowFullScreen={false}
                  ></iframe>
                </div>
              )}
            </div>
          </div>
        </Wrapper>
      </div>
    </AppLayout>
  );
}
