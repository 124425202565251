import React, { useState } from "react";
import "./arrowBtn.scss";

import arrowLeftWhite from "./assets/arrow_whiteLeft.svg";
import arrowRightWhite from "./assets/arrow_whiteRight.svg";
import arrowLeftBlack from "./assets/arrow_blackLeft.svg";
import arrowRightBlack from "./assets/arrow_blackRight.svg";

const ArrowBtn = ({ left, isBeginning1, isEnd1 }) => {
  const [mouseEnter, setMouseEnter] = useState(false);

  return (
    <>
      {left ? (
        <div
          className={isBeginning1 === true ? "arrowBtnC disabled" : "arrowBtnC"}
          onMouseEnter={() => {
            setMouseEnter(true);
          }}
          onMouseLeave={() => {
            setMouseEnter(false);
          }}
        >
          {mouseEnter ? (
            <img src={arrowLeftWhite} alt="" className="arrowBtnC__img" />
          ) : (
            <img src={arrowLeftBlack} alt="" className="" />
          )}
        </div>
      ) : (
        <div
          className={isEnd1 === true ? "arrowBtnC disabled" : "arrowBtnC"}
          onMouseEnter={() => {
            setMouseEnter(true);
          }}
          onMouseLeave={() => {
            setMouseEnter(false);
          }}
        >
          {mouseEnter ? (
            <img src={arrowRightWhite} alt="" className="arrowBtnC__img" />
          ) : (
            <img src={arrowRightBlack} alt="" className="" />
          )}
        </div>
      )}
    </>
  );
};

export default ArrowBtn;
