// var BASE_URL = "https://103.117.194.170/api";
// var BASE_URL = "https://www.tocoma.asia/api";
var BASE_URL = "https://www.tocoma.co/api";

//
export const ROOT_URL = BASE_URL;

// ABOUT TOCOMA
export const ABOUT_TOCOMA_URL = BASE_URL + "/AboutTocoma";
export const ABOUT_TOCOMA_HISTORY_URL =
  BASE_URL + "/AboutTocoma/GetTocomaHistory";

// Product Page
export const LANDING_PAGE_PRODUCT_URL = BASE_URL + "/Product"; // landing page product

export const PRODUCT_TYPE_URL = BASE_URL + "/Product";
export const PRODUCT_URL = BASE_URL + "/ProductType";

// Reference Project
export const REFERENCE_PROJECT_URL = BASE_URL + "/ReferenceProject";
export const REFERENCE_PROJECT_TYPE_URL = BASE_URL + "/ReferenceProject";
export const REFERENCE_PROJECT_DETAILS_URL =
  BASE_URL + "/ReferenceProject/GetReferenceProject";

// Reference Project Landing Page Url
export const REFERENCE_PROJECT_LANDING_PAGE_URL =
  BASE_URL + "/ReferenceProject/RefProjectByStatus/1";

// Our Partner
// export const OUR_PARTNERS_URL = BASE_URL + "/OurPartners";
export const OUR_PARTNERS_URL = BASE_URL + "/OurPartners";
export const OUR_PARTNER_DETAILS_URL = BASE_URL + "/OurPartners/GetPartnerById";

// news and events
export const NEWS_AND_EVENTS_URL = BASE_URL + "/NewsAndEvents";

// Target Market
// export const TARGET_MARKET_URL = BASE_URL + "/TMarket";
export const TARGET_MARKET_URL = BASE_URL + "/TMarket/GetTargetMarket";

// work with us
export const WORK_WITH_US_URL = BASE_URL + "/WorkWithUs";

// download page
export const DOWNLOAD_PAGE_URL = BASE_URL + "/Download";

export const DEMO_URL = "https://103.117.194.170:7002/api/OurPartners";

// var BASE = "https://www.tocoma.co/api/Ourpartners";
