import React, { useEffect, useState } from "react";
import "./landingPartners.scss";
import { Link } from "react-router-dom";

// apis
import { getOurPartnerData } from "../../../apis/ourPartners.apis";

// components
import Wrapper from "../../../components/common/wrapper/Wrapper";
import PrimaryBtn from "../../../components/buttons/primaryBtn/PrimaryBtn";

const LandingPartners = () => {
  const [partnersData, setPartnersData] = useState([]);
  useEffect(() => {
    getOurPartnerData()
      .then((res) => {
        setPartnersData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="landingPartners">
      <Wrapper>
        <div className="landingPartners__container">
          <div className="homeSection__headerDesc">
            <span className="homeSection__headerText">Our Partners</span>

            <span className="homeSection__descText">
              At TOCOMA Limited, our commitment to excellence is strengthened by
              our strategic partnerships with some of the most respected names
              in the industry. We collaborate with them to bring world-class
              construction solutions to Bangladesh. These partnerships enable us
              to leverage global expertise and cutting-edge technology, ensuring
              that we provide innovative, reliable, and high-quality products to
              our customers.
            </span>
          </div>

          <div className="landingPage__partnerList__container">
            {partnersData &&
              partnersData?.map((item, index) => {
                return (
                  <Link
                    to={`/partners/${item?.BRAND_ID}`}
                    className="partnerList__card"
                    key={index}
                  >
                    <div className="partnerList__card_image">
                      <img src={item.LOGO} alt="" />
                    </div>
                  </Link>
                );
              })}
          </div>

          <div className="landingPartners__bottom">
            <PrimaryBtn
              link={"/partners"}
              name={"View All"}
              color={"black"}
              size={"large"}
            />
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default LandingPartners;
