import React, { useEffect, useRef, useState } from "react";
import "./landingAbout.scss";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/swiper-bundle.css";
import SwiperCore, { Scrollbar } from "swiper";

// apis
import { getAboutData } from "../../../apis/aboutTocoma.apis";

// components
import Wrapper from "../../../components/common/wrapper/Wrapper";
import PrimaryBtn from "../../../components/buttons/primaryBtn/PrimaryBtn";

const HomeAbout = () => {
  SwiperCore.use([Scrollbar]);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [totalSlides, setTotalSlides] = useState(0);
  const swiperRef = useRef(null);

  const updateCurrentSlide = () => {
    setCurrentSlide(swiperRef.current.swiper.realIndex);
  };

  const [aboutData, setAboutData] = useState([]);
  useEffect(() => {
    getAboutData()
      .then((res) => {
        setAboutData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="homeAbout">
      <Wrapper>
        <div className="homeAbout__container">
          <div className="homeSection__headerDesc">
            <span className="homeSection__headerText">About TOCOMA</span>

            <span className="homeSection__descText">
              TOCOMA Limited is today a forefront supplier of quality products
              for the Concrete and Masonry industry based in Dhaka, Bangladesh.
              Founded in 2013, we have grown steadily to become a leading
              supplier of Construction Chemical and Building Products in the
              Construction Industry of Bangladesh.
            </span>
          </div>

          <Swiper
            className="homeAbout__swiper"
            loop={true}
            // slidesPerView={3}
            spaceBetween={30}
            ref={swiperRef}
            onSlideChange={(swiper) => {
              updateCurrentSlide();
            }}
            onSwiper={(swiper) => {
              setTotalSlides(swiper.slides.length);
            }}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 48,
              },
            }}
          >
            {aboutData?.slice(1, 4).map((slide, index) => {
              return (
                <SwiperSlide className="homeAbout__swiperSlide" key={index}>
                  <Link
                    to="/about"
                    className="homeAbout__swiperSlideContainer link"
                  >
                    <div className="homeAbout__slideImage">
                      <img src={slide?.BANNER_IMAGE_LINK} alt="" />

                      <span className="homeAbout__slideTitle">
                        {slide?.BANNER_TITLE}
                      </span>
                    </div>

                    <div className="homeAbout__slideContent">
                      <span className="homeAbout__slideDesc">
                        {parse(slide?.DESCRIPTION.slice(0, 280) + "...")}
                      </span>
                    </div>
                  </Link>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <div className="homeAbout__btn">
            <PrimaryBtn
              link={"/about"}
              name={"Learn More"}
              color={"black"}
              size={"large"}
            />
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default HomeAbout;
