import React, { useEffect, useState } from "react";
import "./aboutDetailPage.scss";
import { Link, useNavigate } from "react-router-dom";

// api service
import { getAboutCarouselData } from "../../../apis/hero.apis";

// components
import Wrapper from "../../../components/common/wrapper/Wrapper";
import SubHero from "../../../components-ui/heroSections/subHero/SubHero";
import AppLayout from "../../../components/layouts/appLayout/AppLayout";

const AboutDetailPage = () => {
  const navigate = useNavigate();

  const [slideData, setSlideData] = useState([]);
  useEffect(() => {
    // slide data
    getAboutCarouselData()
      .then((res) => {
        setSlideData(res.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <AppLayout>
      <div className="aboutDetailPage">
        <SubHero data={slideData} />

        <Wrapper>
          <div className="common__headerLink__component">
            <Link to="/" className="common__headerLinkContainer">
              <span className="linkText">Home</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <Link to="/about" className="common__headerLinkContainer">
              <span className="linkText">About Page</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <div
              className="common__headerLinkContainer common__headerLinkContainer__active"
              onClick={() => navigate(-1)}
            >
              <span className="linkText">About Detail</span>
              <i className="fa-solid fa-angle-right"></i>
            </div>
          </div>
        </Wrapper>
      </div>
    </AppLayout>
  );
};

export default AboutDetailPage;
