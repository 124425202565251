import React, { useEffect, useState } from "react";
import "./landingReferenceProject.scss";
import { Link } from "react-router-dom";

// apis
import { getReferenceProjectLandingPageData } from "../../../apis/reference.apis";

// components
import Wrapper from "../../../components/common/wrapper/Wrapper";
import PrimaryBtn from "../../../components/buttons/primaryBtn/PrimaryBtn";

const LandingReferenceProject = () => {
  const [referenceProjectsData, setReferenceProjectsData] = useState([]);
  useEffect(() => {
    getReferenceProjectLandingPageData()
      .then((res) => {
        setReferenceProjectsData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="landingReferenceProject1234">
      <Wrapper>
        <div className="landingRefProject__container">
          <div className="homeSection__headerDesc">
            <span className="homeSection__headerText">Reference Projects</span>

            <span className="homeSection__descText">
              At TOCOMA Limited, our expertise and innovative solutions have
              been applied to a wide range of successful projects across
              Bangladesh. Each project showcases our commitment to quality,
              precision, and excellence in the construction industry.
            </span>
          </div>

          <div className="landingRefProject__itemContainer">
            {referenceProjectsData.slice(0, 3).map((item, index) => {
              return (
                <Link
                  to={`/reference/${item?.REF_PROJECT_TYPE_ID}/${item?.REF_PROJECT_ID}`}
                  className="landingRefProject__item link"
                  key={index}
                >
                  <div className="landingRefProject__itemImage">
                    <img src={item?.PROJECT_IMAGE} alt="" />
                  </div>

                  <div className="landingRefProject__itemText">
                    <span className="landingRefProject__itemTitle">
                      {item?.PROJECT_NAME}
                    </span>

                    {/* <span className="landingRefProject__itemLocation">
                      <i className="fas fa-map-marker-alt"></i>{" "}
                      {item?.PROJECT_ADDRESS}
                    </span> */}
                  </div>
                </Link>
              );
            })}
          </div>

          <div className="landingRefProject__itemContainer2">
            {referenceProjectsData.slice(3, 6).map((item, index) => {
              return (
                <Link
                  to={`/reference/${item?.REF_PROJECT_TYPE_ID}/${item?.REF_PROJECT_ID}`}
                  className="landingRefProject__item link"
                  key={index}
                >
                  <div className="landingRefProject__itemImage">
                    <img src={item?.PROJECT_IMAGE} alt="" />
                  </div>

                  <div className="landingRefProject__itemText">
                    <span className="landingRefProject__itemTitle">
                      {item?.PROJECT_NAME}
                    </span>

                    {/* <span className="landingRefProject__itemLocation">
                      <i className="fas fa-map-marker-alt"></i>{" "}
                      {item?.PROJECT_ADDRESS}
                    </span> */}
                  </div>
                </Link>
              );
            })}
          </div>

          <div className="landingRefProject__btnContainer">
            <PrimaryBtn
              link={"/reference"}
              name={"View All Projects"}
              color={"black"}
              size={"large"}
            />
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default LandingReferenceProject;
