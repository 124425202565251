import React from "react";
import "./footer.scss";
import { Link } from "react-router-dom";

// image
import bottomLine from "./image/footer_line.png";

// icons
import { RiFacebookFill } from "react-icons/ri";
import { FaXTwitter } from "react-icons/fa6";
import { RiLinkedinFill } from "react-icons/ri";
import { FaYoutube } from "react-icons/fa";

// components import
import Wrapper2 from "../wrapper2/Wrapper2";

export default function Footer() {
  return (
    <div className="footer">
      <Wrapper2>
        <div className="footerContainer">
          <div className="footer__topContainer">
            <div className="footer__topContainer__left">
              <div className="footer__companyInfo">
                <div className="footer__companyName">
                  <Link to="/" className="footerLogo link">
                    TOCOMA Limited
                  </Link>
                </div>

                <div className="company__infoText">
                  <span>
                    1005 Mailbagh Chowdhurypara,
                    <br /> Dhaka-1219, Bangladesh
                  </span>
                </div>

                <div className="company__infoText">
                  <span>info@tocoma.com.bd</span>
                  <span>+88 0176 7456459</span>
                </div>
              </div>

              <div className="footer__links">
                <Link to="/about" className="footer__link link">
                  About
                </Link>
                <Link to="/products" className="footer__link link">
                  Products
                </Link>
              </div>

              <div className="footer__links">
                <Link to="/partners" className="footer__link link">
                  Partners
                </Link>
                <Link to="/contact" className="footer__link link">
                  Contact
                </Link>
              </div>

              {/* <div className="footer__socialLinks">
                <Link to="" className="footer__socialLink">
                  <FaXTwitter />
                </Link>

                <Link to="" className="footer__socialLink">
                  <RiFacebookFill />
                </Link>

                <Link to="" className="footer__socialLink">
                  <RiLinkedinFill />
                </Link>

                <Link to="" className="footer__socialLink">
                  <FaYoutube />
                </Link>
              </div> */}
            </div>

            <div className="footer__topContainer__right">
              <div className="footer__topContainer__right__img">
                <img src={require("./image/footer-btext.png")} alt="logo" />
              </div>
            </div>
          </div>
        </div>
      </Wrapper2>

      <div className="footer__bottomContainer">
        <Wrapper2>
          <span>
            © Copyright TOCOMA Limited {new Date().getFullYear()}. All Right
            Reserved
          </span>
        </Wrapper2>

        <img src={bottomLine} alt="logo" />
      </div>
    </div>
  );
}
