import React, { useEffect, useState } from "react";
import "./openPositionDetails.scss";
import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";

// icon
import { IoIosSquare } from "react-icons/io";

// api import
import { getPositionDetailsById } from "../../../apis/workWithUs.apis";

// components import
import Wrapper from "../../../components/common/wrapper/Wrapper";
import SubHero from "../../../components-ui/heroSections/subHero/SubHero";
import AppLayout from "../../../components/layouts/appLayout/AppLayout";
import BorderBtn from "../../../components/buttons/borderBtn/BorderBtn";

const OpenPositionDetails = () => {
  const { positionId } = useParams();

  const [positionDetails, setPositionDetails] = useState({});
  useEffect(() => {
    getPositionDetailsById(positionId)
      .then((res) => {
        setPositionDetails(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [positionId]);

  return (
    <AppLayout>
      <div className="openPositionDetails">
        <SubHero />

        <Wrapper>
          <div className="common__headerLink__component">
            <Link to="/" className="common__headerLinkContainer">
              <span className="linkText">Home</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <Link to="/career" className="common__headerLinkContainer ">
              <span className="linkText">Career</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <Link
              to={`/career/career-list`}
              className="common__headerLinkContainer "
            >
              <span className="linkText">Position List</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <div className="common__headerLinkContainer common__headerLinkContainer__active">
              <span className="linkText">Position Details</span>
            </div>
          </div>

          <div className="openPositionDetails__container">
            <div className="openPositionDetails__content">
              <div className="opc__top">
                <div className="opc__positionInfos">
                  <h4 className="opc__title">{positionDetails?.POSITION}</h4>

                  <div className="opc__positionInfoList">
                    <span>Experiance : {positionDetails?.EXPERIANCE}</span>
                    <span>
                      Contract Type : {positionDetails?.CONTRACT_TYPE}
                    </span>
                    <span>Job Type : {positionDetails?.EMPLOYMENT_STATUS}</span>
                    <span>Location : {positionDetails?.JOB_LOCATION}</span>
                  </div>
                </div>

                <div className="openPositionDetails__btn">
                  <BorderBtn
                    link={`/career/apply/${positionId}`}
                    name="Apply"
                    color={"black"}
                  />
                </div>
              </div>

              {/* <div className="opc__detailsContainer">
                <h3 className="opc__title">About Position</h3>

                {positionDetails?.JOB_SUMMERY &&
                  parse(positionDetails?.JOB_SUMMERY)}
              </div> */}

              {positionDetails?.detailsItem &&
                positionDetails?.detailsItem?.map((item, index) => (
                  <div className="opc__detailsContainer" key={index}>
                    <h3 className="opc__title">{item.TITLE}</h3>

                    {item?.DESCRIPTION && parse(item?.DESCRIPTION)}

                    {/* {item?.itemList && (
                      <div className="opc__detailsItemList">
                        {item?.itemList.map((l, index) => (
                          <div className="opc__detailsItem" key={index}>
                            <IoIosSquare className="opc__detailsItemBullet" />

                            {l.DETAILS_ITEM && parse(l.DETAILS_ITEM)}
                          </div>
                        ))}
                      </div>
                    )} */}
                  </div>
                ))}

              <div className="opc__bottom">
                <span>
                  We invite talented individuals to apply for these positions
                  and join our dynamic team.
                  <br /> <br />
                  We encourage all interested candidates to apply through our
                  online registration portal for a streamlined application
                  process. If online registration is not possible, you may send
                  your application by email to :{" "}
                  <span>career@tocoma.com.bd</span>
                </span>

                <BorderBtn
                  link={`/career/apply/${positionId}`}
                  name="Apply"
                  color={"black"}
                />
              </div>
            </div>
          </div>
        </Wrapper>
      </div>
    </AppLayout>
  );
};

export default OpenPositionDetails;
