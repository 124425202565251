import React, { useEffect, useState } from "react";
import "./contactUs.scss";
import { Link, useNavigate } from "react-router-dom";

// select component
import Select from "react-select";

// data
import { distributorsData } from "./data";

// api services
import { getSubHeadersData } from "../../apis/subHeader.apis";
import {
  getContactInfo,
  getContactDropdownItems,
  submitContactForm,
} from "../../apis/contact.apis";

// component imports
import Wrapper from "../../components/common/wrapper/Wrapper";
import SubHero from "../../components-ui/heroSections/subHero/SubHero";
import AppLayout from "../../components/layouts/appLayout/AppLayout";

const ContactUs = () => {
  const navigate = useNavigate();

  // state
  const [subHeaderData, setSubHeaderData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [contactInfo, setContactInfo] = useState({});
  const [dropdownItems, setDropdownItems] = useState([]);
  const [reqForItems, setReqForItems] = useState([]);
  const [productItems, setProductItems] = useState([]);

  const [contactInputs, setContactInputs] = useState({
    NAME: "",
    DESIGNATION: "",
    MOBILE_NO: "",
    EMAIL_ADDRESS: "",
    ADDRESS: "",
    COUNTRY: "",
    COMPANY_NAME: "",
    REQUEST_FOR: "",
    PRODUCT_ID: "",
    MESSAGE: "",
  });

  const onChangeHandler = (e, name) => {
    setContactInputs({ ...contactInputs, [name]: e.target.value });
  };

  useEffect(() => {
    // sub header
    getSubHeadersData()
      .then((res) => {
        const data = res.data.filter((item) => item.PAGE_SHORT_NAME === "CN");
        setSubHeaderData(data);
      })
      .catch((err) => {
        console.log(err);
      });

    getContactInfo()
      .then((res) => {
        setContactInfo(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    getContactDropdownItems()
      .then((res) => {
        setDropdownItems(res.data);
        const formatedReqForItems = res.data.requestforItemList.map((item) => ({
          value: item,
          label: item,
        }));
        setReqForItems(formatedReqForItems);

        const formatedProductItems = res.data.itemList.map((item) => ({
          value: item.ITEM_ID,
          label: item.ITEM_NAME,
        }));
        setProductItems(formatedProductItems);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const formValidation = () => {
    const EMAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    const MOBILE_REGEX = /^[0-9]{11}$/;

    if (
      contactInputs.NAME === "" ||
      contactInputs.DESIGNATION === "" ||
      contactInputs.MOBILE_NO === "" ||
      contactInputs.EMAIL_ADDRESS === "" ||
      contactInputs.ADDRESS === "" ||
      contactInputs.COUNTRY === "" ||
      contactInputs.COMPANY_NAME === "" ||
      contactInputs.REQUEST_FOR === ""
    ) {
      setError("Please fill required fields");
      return false;
    } else if (!EMAIL_REGEX.test(contactInputs.EMAIL_ADDRESS)) {
      setError("Please enter a valid email address");
      return false;
    } else if (!MOBILE_REGEX.test(contactInputs.MOBILE_NO)) {
      setError("Please enter a valid mobile number");
      return false;
    } else {
      setError(null);
      return true;
    }
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    if (!formValidation()) {
      return;
    }

    const formData = new FormData();
    formData.append("NAME", contactInputs.NAME);
    formData.append("DESIGNATION", contactInputs.DESIGNATION);
    formData.append("MOBILE_NO", contactInputs.MOBILE_NO);
    formData.append("EMAIL_ADDRESS", contactInputs.EMAIL_ADDRESS);
    formData.append("ADDRESS", contactInputs.ADDRESS);
    formData.append("COUNTRY", contactInputs.COUNTRY);
    formData.append("COMPANY_NAME", contactInputs.COMPANY_NAME);
    formData.append("REQUEST_FOR", contactInputs.REQUEST_FOR);
    formData.append("PRODUCT_ID", contactInputs.PRODUCT_ID);
    formData.append("MESSAGE", contactInputs.MESSAGE);

    submitContactForm(formData)
      .then((res) => {
        // console.log(res);
        setContactInputs({
          NAME: "",
          DESIGNATION: "",
          MOBILE_NO: "",
          EMAIL_ADDRESS: "",
          ADDRESS: "",
          COUNTRY: "",
          COMPANY_NAME: "",
          REQUEST_FOR: "",
          PRODUCT_ID: "",
          MESSAGE: "",
        });

        setTimeout(() => {
          navigate("/confirmation");
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <AppLayout>
      <div className="contactUs">
        <SubHero
          title={subHeaderData && subHeaderData[0]?.TITLE}
          desc={subHeaderData && subHeaderData[0]?.DESCRIPTION}
        />

        <Wrapper>
          <div className="common__headerLink__component">
            <Link to="/" className="common__headerLinkContainer">
              <span className="linkText">Home</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <div className="common__headerLinkContainer common__headerLinkContainer__active">
              <span className="linkText">Contact Us</span>
            </div>
          </div>

          <div className="contactUs__container">
            <div className="contactUs__mapAnddetails">
              <div className="contactUs__map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d456.49078291652165!2d90.41540483323615!3d23.750008731502252!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b99897e2ffad%3A0x8530c175fcfaf178!2sTOCOMA%20Limited!5e0!3m2!1sen!2sbd!4v1688448335315!5m2!1sen!2sbd"
                  width="100%"
                  height="100%"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="map"
                ></iframe>
              </div>

              <div className="contactUs__details">
                <div className="contactUs__detailsContainer__headerAndDetails">
                  <h4 className="contactUs__detailsContainer__headerText">
                    Headquarters
                  </h4>

                  <span className="contactUs__detailsContainer__detailsText">
                    {contactInfo?.ADDRESS}
                  </span>
                </div>

                <div className="contactUs__detailsContainer__headerAndDetails">
                  <h4 className="contactUs__detailsContainer__headerText">
                    Mobile
                  </h4>

                  <span className="contactUs__detailsContainer__detailsText">
                    {contactInfo?.MOBILE}
                  </span>
                </div>

                <div className="contactUs__detailsContainer__headerAndDetails">
                  <h4 className="contactUs__detailsContainer__headerText">
                    Email
                  </h4>

                  <span className="contactUs__detailsContainer__detailsText">
                    {contactInfo?.EMAIL}
                  </span>
                </div>

                <div className="contactUs__detailsContainer__headerAndDetails">
                  <h4 className="contactUs__detailsContainer__headerText">
                    Web URL
                  </h4>

                  <a
                    href={`http://${contactInfo?.WEB_URL}`}
                    target="_blank"
                    rel="noreferrer"
                    className="contactUs__detailsContainer__detailsText"
                  >
                    {contactInfo?.WEB_URL}
                  </a>
                </div>
              </div>
            </div>

            <div className="contactUs__contactAndForm">
              <div className="contactUs__contactHeaderDesc">
                <h3 className="contactUs__contactHeader__text">Get in Touch</h3>
                <span className="contactUs__contactUs__contactDesc__text">
                  Fill out the form below to connect with our team. Whether
                  you're looking for product information, project inquiries, or
                  partnership opportunities, we look forward to hearing from
                  you.
                </span>
              </div>

              <form
                action=""
                onSubmit={onSubmitHandler}
                className="contactUs__contactForm"
              >
                <div className="constct__fornInputContainer">
                  <span>Fields marked with * are required.</span>

                  <div className="contactUs__inputRow__type1">
                    <div className="contactUs__inputGroup">
                      <input
                        type="text"
                        className="contactInput"
                        placeholder="Name *"
                        name={"NAME"}
                        value={contactInputs.NAME}
                        onChange={(e) => onChangeHandler(e, "NAME")}
                      />
                    </div>

                    <div className="contactUs__inputGroup">
                      <input
                        type="text"
                        className="contactInput"
                        placeholder="Designation"
                        name="DESIGNATION"
                        value={contactInputs.DESIGNATION}
                        onChange={(e) => onChangeHandler(e, "DESIGNATION")}
                      />
                    </div>

                    <div className="contactUs__inputGroup">
                      <input
                        type="number"
                        className="contactInput"
                        placeholder="Mobile Number *"
                        name="MOBILE_NO"
                        value={contactInputs.MOBILE_NO}
                        onChange={(e) => onChangeHandler(e, "MOBILE_NO")}
                      />
                    </div>
                  </div>

                  <div className="contactUs__inputRow__type2">
                    <div className="contactUs__inputGroup">
                      <input
                        type="email"
                        className="contactInput"
                        placeholder="Email *"
                        name="EMAIL_ADDRESS"
                        value={contactInputs.EMAIL_ADDRESS}
                        onChange={(e) => onChangeHandler(e, "EMAIL_ADDRESS")}
                      />
                    </div>

                    <div className="contactUs__inputGroup">
                      <input
                        type="text"
                        className="contactInput"
                        placeholder="Address"
                        name="ADDRESS *"
                        value={contactInputs.ADDRESS}
                        onChange={(e) => onChangeHandler(e, "ADDRESS")}
                      />
                    </div>

                    <div className="contactUs__inputGroup">
                      <input
                        type="text"
                        className="contactInput"
                        placeholder="Country"
                        name="COUNTRY"
                        value={contactInputs.COUNTRY}
                        onChange={(e) => onChangeHandler(e, "COUNTRY")}
                      />
                    </div>
                  </div>

                  <div className="contactUs__inputRow__type3">
                    <div className="contactUs__inputGroup">
                      <input
                        type="text"
                        className="contactInput"
                        placeholder="Company Name"
                        name="COMPANY_NAME *"
                        value={contactInputs.COMPANY_NAME}
                        onChange={(e) => onChangeHandler(e, "COMPANY_NAME")}
                      />
                    </div>

                    <div className="contactUs__inputGroup">
                      <Select
                        options={reqForItems}
                        placeholder="Request For"
                        onChange={(e) => {
                          setContactInputs({
                            ...contactInputs,
                            REQUEST_FOR: e.value,
                          });
                        }}
                        className="react-select-container contactSelectCustom"
                        classNamePrefix="react-select"
                      />
                    </div>

                    <div className="contactUs__inputGroup">
                      {/* <select
                        className="contactSelect"
                        name="PRODUCT_ID"
                        value={contactInputs.PRODUCT_ID}
                        onChange={(e) => onChangeHandler(e, "PRODUCT_ID")}
                      >
                        <option value="" disabled>
                          Select Product
                        </option>
                        {dropdownItems?.itemList?.map((item, index) => (
                          <option key={index} value={item.ITEM_ID}>
                            {item.ITEM_NAME.length > 30
                              ? item.ITEM_NAME.slice(0, 30) + "..."
                              : item.ITEM_NAME}
                          </option>
                        ))}
                      </select> */}

                      <Select
                        options={productItems}
                        placeholder="Select Product"
                        onChange={(e) => {
                          setContactInputs({
                            ...contactInputs,
                            PRODUCT_ID: e.value,
                          });
                        }}
                        className="react-select-container contactSelectCustom"
                        classNamePrefix="react-select"
                      />
                    </div>
                  </div>

                  <div className="contactUs__inputRow__type4">
                    <div className="contactUs__inputGroup">
                      <textarea
                        id=""
                        className="contactTextArea"
                        placeholder="Your message"
                        rows={8}
                        name="MESSAGE"
                        value={contactInputs.MESSAGE}
                        onChange={(e) => onChangeHandler(e, "MESSAGE")}
                      />
                    </div>
                  </div>
                </div>

                <div className="contactUs__btnContainer">
                  <div className="contactUs__btnLeft">
                    <div className="contactUs__checkBoxContainer">
                      <span className="contactUs__checkBoxContainer__headerText">
                        We will call you back on request
                      </span>

                      <div className="checkboxContainer">
                        <input
                          type="checkbox"
                          className="contactUs__checkbox"
                        />
                        <label htmlFor="data_protection"> Please call me</label>
                      </div>
                    </div>
                    <div className="contactUs__checkBoxContainer">
                      <span className="contactUs__checkBoxContainer__headerText">
                        Data protection
                      </span>

                      <div className="checkboxContainer">
                        <input
                          type="checkbox"
                          className="contactUs__checkbox"
                        />
                        <label htmlFor="data_protection"> I'm agree</label>
                      </div>
                    </div>
                  </div>

                  <div className="contactUs__btnRight">
                    {error && (
                      <span className="contactUs__errorText">{error}</span>
                    )}

                    <button type="submit" className="contactUs__subimtBtn">
                      Submit
                    </button>
                  </div>
                  {/* <BorderBtn name={"Submit"} color="white" type="submit" /> */}
                </div>
              </form>
            </div>

            {/* <div className="contactUs__distributors">
              <div className="contactUs__dHeaderDesc">
                <h3>Distributors</h3>

                <span>
                  Fill out the form below to connect with our team. Whether
                  you're looking for product information, project inquiries, or
                  partnership opportunities, we look forward to hearing from
                  you.
                </span>
              </div>

              <div className="contactUs__distributorsList">
                {distributorsData.map((data, index) => (
                  <div key={index} className="contactUs__distributor">
                    <h3 className="contactUs__distributor__nameText">
                      {data.title}
                    </h3>

                    <div className="contactUs__distributorInfo">
                      <div className="contactUs__distributorInfo__address">
                        <h4>Address</h4> <span>{data.address}</span>
                      </div>

                      <div className="contactUs__distributorInfo__phone">
                        <h4>Phone</h4> <span>{data.phone}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div> */}
          </div>
        </Wrapper>
      </div>
    </AppLayout>
  );
};

export default ContactUs;
