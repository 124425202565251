import React, { useEffect, useState } from "react";
import "./aboutPage.scss";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

// img
import companyBrocure from "./assets/company_brocure.png";
import pdfIcon from "./assets/pdfIcon.png";

// api services import
import { getSubHeadersData } from "../../../apis/subHeader.apis";
import { getAboutData } from "../../../apis/aboutTocoma.apis";

// componets import
import Wrapper from "../../../components/common/wrapper/Wrapper";
import SubHero from "../../../components-ui/heroSections/subHero/SubHero";
import AppLayout from "../../../components/layouts/appLayout/AppLayout";
import AboutTeam from "./components/aboutTeam/AboutTeam";
import AboutHistory from "./components/aboutHistory/AboutHistory";
import AboutMissionVission from "./components/collapsibles/aboutMissionVission/AboutMissionVission";
import AboutCoreValues from "./components/collapsibles/aboutCoreValues/AboutCoreValues";
import CustomerSolutions from "./components/collapsibles/customerSolutions/CustomerSolutions";
import BusinessPrinciples from "./components/collapsibles/businessPrinciples/BusinessPrinciples";

export default function AboutPage() {
  const [subHeaderData, setSubHeaderData] = useState([]);
  const [aboutData, setAboutData] = useState([]);

  useEffect(() => {
    // sub header
    getSubHeadersData()
      .then((res) => {
        const data = res.data.filter((item) => item.PAGE_SHORT_NAME === "AB");
        setSubHeaderData(data);
      })
      .catch((err) => {
        console.log(err);
      });

    getAboutData()
      .then((res) => {
        setAboutData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // states
  const [missionData, setMissionData] = useState([]);
  const [vissionData, setVissionData] = useState([]);
  const [coreValuesData, setCoreValuesData] = useState([]);
  const [customerSolutionsData, setCustomerSolutionsData] = useState([]);
  const [businessPrinciplesData, setBusinessPrinciplesData] = useState([]);

  useEffect(() => {
    // filter data and set to state
    aboutData &&
      aboutData.map((data) => {
        if (data?.BANNER_TITLE === "Mission") {
          setMissionData(data);
        } else if (data?.BANNER_TITLE === "Vision") {
          setVissionData(data);
        } else if (data?.BANNER_TITLE === "Core Values") {
          setCoreValuesData(data);
        } else if (data?.BANNER_TITLE === "Customer Solutions") {
          setCustomerSolutionsData(data);
        } else if (data?.BANNER_TITLE === "Business Principles") {
          setBusinessPrinciplesData(data);
        }
      });
  }, [aboutData]);

  return (
    <AppLayout>
      <div className="aboutPage">
        <SubHero
          title={subHeaderData && subHeaderData[0]?.TITLE}
          desc={subHeaderData && subHeaderData[0]?.DESCRIPTION}
        />

        <Wrapper>
          <div className="common__headerLink__component">
            <Link to="/" className="common__headerLinkContainer">
              <span className="linkText">Home</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <div className="common__headerLinkContainer common__headerLinkContainer__active">
              <span className="linkText">About Tocoma</span>
            </div>
          </div>

          <div className="aboutUs__container">
            <div className="about__infoContainer">
              <h3 className="aboutUs__header">About Us</h3>

              {aboutData && aboutData[0]?.DESCRIPTION && (
                <span>{parse(aboutData[0]?.DESCRIPTION)}</span>
              )}
            </div>

            <div className="aboutUsList__container">
              <AboutMissionVission
                isCollapsOpen={false}
                missionData={missionData}
                vissionData={vissionData}
              />
              <AboutCoreValues isCollapsOpen={false} data={coreValuesData} />
              <CustomerSolutions
                isCollapsOpen={false}
                data={customerSolutionsData}
              />
              <BusinessPrinciples
                isCollapsOpen={false}
                data={businessPrinciplesData}
              />
            </div>

            {aboutData.VIDEO_LINK && (
              <div className="aboutPage__videoContainer">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/7kQfJk3Zl3Q"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            )}
          </div>
        </Wrapper>

        <div className="aboutPage__history">
          <Wrapper>
            <AboutHistory />
          </Wrapper>
        </div>

        {/* <Wrapper>
          <section className="aboutPage__teamAndclient">
            <AboutTeam />
          </section>
        </Wrapper>

        <div className="company__profile">
          <Wrapper>
            <div className="company__profileContainer">
              <div className="company__profile__left">
                <span>
                  We invite you to download our company profile and product
                  brochure to learn more about our comprehensive range of
                  solutions. Click the link below to get started:
                </span>
              </div>

              <div className="company__profile__right">
                <img src={companyBrocure} alt="company" />

                <div className="companyPdf">
                  <img src={pdfIcon} alt="pdf" />
                  <span>Download</span>
                </div>
              </div>
            </div>
          </Wrapper>
        </div> */}
      </div>
    </AppLayout>
  );
}
