export const btnData = [
  {
    id: 1,
    title: "Overview",
    slug: "overview",
  },
  {
    id: 2,
    title: "Advantage",
    slug: "advantage",
  },
  {
    id: 3,
    title: "Application Area",
    slug: "application-area",
  },
  {
    id: 4,
    title: "Precaution",
    slug: "precaution",
  },
  {
    id: 5,
    title: "Documents",
    slug: "product-documents",
  },
];
