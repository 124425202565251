import React from "react";
import "./navbar.scss";
import { Link, NavLink } from "react-router-dom";

// icon
import { FaSearch } from "react-icons/fa";
import { FiMenu } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";

// logo
import companyLogo from "./components/assets/Tocoma-Logo.svg";

// data
import { menuData } from "./menuData";

// components
import Wrapper2 from "../wrapper2/Wrapper2";
import SearchItems from "./components/searchItems/SearchItems";

const Navbar = () => {
  // menu
  const openMobileMenu = () => {
    const menu = document.querySelector(".mobile__menuContainer");
    menu.classList.toggle("active");
  };

  const closeMobileMenu = () => {
    const menu = document.querySelector(".mobile__menuContainer");
    menu.classList.remove("active");
  };

  // new search
  const handleSearchClick = () => {
    const search = document.querySelector(".navba__searchContainer");
    search.classList.toggle("active");
  };
  return (
    <div className="navbar" id="top">
      <Wrapper2>
        <div className="navbar__container">
          <div className="navbar__left">
            <Link to="/" className="navbar__logo">
              <img src={companyLogo} alt="" />
            </Link>
          </div>

          <div className="navbar__right">
            <div className="navbar__menu">
              {menuData.map((item, index) => (
                <NavLink
                  key={index}
                  to={item.link}
                  className={({ isActive }) =>
                    isActive
                      ? "navbar__menuItem link active"
                      : "navbar__menuItem link"
                  }
                >
                  {item.title}
                </NavLink>
              ))}

              {/* <div className="navbar__shop">
                <a
                  href="https://shop.tocoma.com.bd"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="navbar__shopText link"
                >
                  TOCOMA Store
                </a>
              </div> */}

              <div className="navbar__searchIcon">
                <FaSearch onClick={handleSearchClick} />

                <div className="navba__searchContainer">
                  <SearchItems />
                </div>
              </div>
            </div>

            <FiMenu className="mobile__menuIcon" onClick={openMobileMenu} />

            <div className="mobile__menuContainer">
              <div className="mobile__menuContent">
                <RxCross2
                  className="mobile__closeIcon"
                  onClick={closeMobileMenu}
                />

                <div className="mobile__menuItems">
                  {menuData.map((item, index) => (
                    <NavLink
                      key={index}
                      to={item.link}
                      className={({ isActive }) =>
                        isActive
                          ? "navbar__menuItem link active"
                          : "navbar__menuItem link"
                      }
                    >
                      {item.title}
                    </NavLink>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper2>
    </div>
  );
};

export default Navbar;
