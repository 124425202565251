import React, { useEffect, useState } from "react";
import "./landingTargetMarket.scss";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

// apis
import { getTargetMarketData } from "../../../apis/targetMarket.apis";

// components
import Wrapper from "../../../components/common/wrapper/Wrapper";
import PrimaryBtn from "../../../components/buttons/primaryBtn/PrimaryBtn";

const LandingTargetMarket = () => {
  const [targetMarketData, setTargetMarketData] = useState([]);
  useEffect(() => {
    getTargetMarketData()
      .then((res) => {
        setTargetMarketData(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div className="landing__targetMarket">
      <Wrapper>
        <div className="landing__targetMarketContainer">
          <div className="homeSection__headerDesc">
            <span className="homeSection__headerText">
              {targetMarketData?.TITLE}
            </span>

            <span className="homeSection__descText">
              {targetMarketData?.DESCRIPTION &&
                parse(targetMarketData?.DESCRIPTION)}
            </span>
          </div>

          <div className="targetMarket__container">
            {targetMarketData?.tmarketList &&
              targetMarketData?.tmarketList?.map((item, index) => {
                return (
                  <Link
                    to="/target-market"
                    className="targerMartet__content link"
                    key={index}
                  >
                    <div className="targerMartet__contentImage">
                      <img src={item?.IMAGE_LINK_1} alt={item.IMAGE_LINK_1} />
                    </div>

                    <div className="targerMartet__layer"></div>

                    <div className="targerMartet__text">
                      <span className="">{item?.NAME}</span>
                    </div>
                  </Link>
                );
              })}
          </div>

          <div className="targetMarket__btn">
            <PrimaryBtn
              link={"/target-market"}
              name={"View All"}
              color={"black"}
              size={"large"}
            />
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default LandingTargetMarket;
