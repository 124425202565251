import React, { useEffect, useState } from "react";
import "./referenceProjectDetails.scss";
import { Link, useNavigate, useParams } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";

// apis import
import { getReferenceProjectDetailData } from "../../../apis/reference.apis";

// components import
import AppLayout from "../../../components/layouts/appLayout/AppLayout";
import Wrapper from "../../../components/common/wrapper/Wrapper";
import SubHero from "../../../components-ui/heroSections/subHero/SubHero";
import ProductCard from "../../../components/cards/productCard/ProductCard";

const ReferenceProjectDetails = () => {
  const { refTypeId } = useParams();
  const { refProjectId } = useParams();

  // api call for project details
  const [projectDetailData, setProjectDetailData] = useState({});
  useEffect(() => {
    getReferenceProjectDetailData({ typeId: refTypeId, pId: refProjectId })
      .then((res) => {
        setProjectDetailData(res.data);
        // console.log("res", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [refTypeId, refProjectId]);
  // console.log("projectDetailData", projectDetailData);

  return (
    <AppLayout>
      <div className="referenceProjectDetails">
        <SubHero title={projectDetailData?.PROJECT_NAME} />

        <Wrapper>
          <div className="common__headerLink__component">
            <Link to="/" className="common__headerLinkContainer">
              <span className="linkText">Home</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <Link to="/reference" className="common__headerLinkContainer">
              <span className="linkText">Reference Project</span>
              <i className="fa-solid fa-angle-right"></i>
            </Link>

            <div className="common__headerLinkContainer">
              <span className="linkText">Project Overview</span>
            </div>
          </div>

          <div className="referenceProjectDetails__container">
            <div className="referenceProjectDetails__projectProfile__Container">
              <div className="referenceProjectDetails__projectProfile__Header">
                <h3>Project Overview</h3>
              </div>

              <div className="projectProfile__contentContainer">
                {projectDetailData.PROJECT_NAME && (
                  <div className="projectProfile__content">
                    <span className="projectProfile__contentHeader">
                      Project Name
                    </span>

                    <ul>
                      <li>{projectDetailData?.PROJECT_NAME}</li>
                    </ul>
                  </div>
                )}

                {projectDetailData.PROJECT_ADDRESS && (
                  <div className="projectProfile__content">
                    <span className="projectProfile__contentHeader">
                      Location
                    </span>

                    <ul>
                      <li>{projectDetailData?.PROJECT_ADDRESS}</li>
                    </ul>
                  </div>
                )}

                {projectDetailData?.PROJECT_OWNER && (
                  <div className="projectProfile__content">
                    <span className="projectProfile__contentHeader">
                      Owner Name
                    </span>

                    <ul>
                      <li>{projectDetailData?.PROJECT_OWNER}</li>
                    </ul>
                  </div>
                )}

                {projectDetailData.PROJECT_CONTRACTOR && (
                  <div className="projectProfile__content">
                    <span className="projectProfile__contentHeader">
                      Contractor
                    </span>
                    <ul>
                      <li>{projectDetailData?.PROJECT_CONTRACTOR}</li>
                    </ul>
                  </div>
                )}

                {projectDetailData.PROJECT_ENGINEER && (
                  <div className="projectProfile__content">
                    <span className="projectProfile__contentHeader">
                      Engineer Name
                    </span>
                    <ul>
                      <li>{projectDetailData.PROJECT_ENGINEER}</li>
                    </ul>
                  </div>
                )}

                {projectDetailData.CLIENT_NAME && (
                  <div className="projectProfile__content">
                    <span className="projectProfile__contentHeader">
                      Client Name
                    </span>

                    <ul>
                      {projectDetailData.CLIENT_NAME.split(",").map(
                        (client, index) => (
                          <li key={index}>{client.trim()}</li>
                        )
                      )}
                    </ul>

                    {/* <ul>
                      <li>{projectDetailData.CLIENT_NAME}</li>
                    </ul> */}
                  </div>
                )}
              </div>
            </div>

            {/* <Swiper
              modules={[Autoplay, Pagination, Navigation]}
              spaceBetween={50}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              autoplay={{ delay: 5000 }}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper) => console.log(swiper)}
              className="referenceProjectDetails__ImageAndHeader"
            >
              {projectDetailData.PROJECT_IMAGE && (
                <SwiperSlide className="referenceProjectDetails__Image">
                  <img
                    src={projectDetailData?.PROJECT_IMAGE}
                    alt="referenceProjectDetails__Image"
                  />

                  <div className="location">
                    <span>{projectDetailData?.PROJECT_NAME}</span>
                  </div>
                </SwiperSlide>
              )}
            </Swiper> */}

            <div className="referenceProjectDetails__ImageAndHeader">
              <div className="referenceProjectDetails__Image">
                {projectDetailData.PROJECT_IMAGE && (
                  <img
                    src={projectDetailData?.PROJECT_IMAGE}
                    alt="referenceProjectDetails__Image"
                  />
                )}

                <div className="location">
                  <span>{projectDetailData?.PROJECT_NAME}</span>
                </div>
              </div>
            </div>

            <div className="projectProfile__projectUsedProduct__container">
              <div className="projectProfile__projectUsedProduct__Header">
                <span>Product Used In This Project</span>
              </div>

              <div className="projectProfile__projectUsedProduct__contentContainer">
                {projectDetailData && projectDetailData.productList === null ? (
                  <span className="refProduct__notFound">No Product Found</span>
                ) : (
                  <>
                    {projectDetailData &&
                      projectDetailData?.productList?.map((item, index) => {
                        return (
                          <ProductCard
                            key={index}
                            data={item}
                            link={
                              "/products/" +
                              item?.CATEGORY_ID +
                              "/" +
                              item?.ITEM_ID
                            }
                          />
                        );
                      })}
                  </>
                )}
              </div>
            </div>

            {projectDetailData.PROJECT_VIDEO_LINK && (
              <div className="refProject__video">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/7kQfJk3Zl3Q"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            )}
          </div>
        </Wrapper>
      </div>
    </AppLayout>
  );
};

export default ReferenceProjectDetails;
