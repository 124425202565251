import React from "react";
import "./partnerCard.scss";
import parse from "html-react-parser";

// components
import PrimaryBtn from "../../../components/buttons/primaryBtn/PrimaryBtn";

const PartnerCard = ({ data, link }) => {
  return (
    <div className="partnerCard">
      <div className="partner_img">
        <img src={data?.IMAGE_LINK} alt="" />
      </div>

      <div className="partner_content">
        <div className="partnerCard_logo">
          <img src={data?.LOGO} alt="logo" />
        </div>

        {data?.DESCRIPTION && (
          <span>
            {parse(
              data?.DESCRIPTION.length > 450
                ? data?.DESCRIPTION.substring(0, 450) + "..."
                : data?.DESCRIPTION
            )}
          </span>
        )}

        <div className="partner_btn">
          <PrimaryBtn name={"Discover"} link={link} size={"medium"} />
        </div>
      </div>
    </div>
  );
};

export default PartnerCard;
